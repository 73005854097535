import { environment } from '~/src/utils/environment';

import { ShippoHttpClient } from './ShippoHttpClient';
import { ShippoHttpClientError } from './ShippoHttpClientErrors';

type DjangoErrorDetailResponse = {
  errors: {
    detail: string;
  }[];
};

export class DjangoHttpClient extends ShippoHttpClient {
  constructor() {
    super(environment.DJANGO_API_BASE_URL);
  }

  public static isErrorDetailResponse<T extends ShippoHttpClientError>(
    error: T,
  ): error is { response: DjangoErrorDetailResponse } & T {
    return Boolean(error.response?.errors) && Array.isArray(error.response?.errors);
  }

  public static mapErrorDetailToMessages<T extends ShippoHttpClientError>(error: T): string[] {
    if (!DjangoHttpClient.isErrorDetailResponse(error)) {
      return [];
    }

    return error.response.errors.map((error) => error.detail);
  }
}

export const djangoHttpClient = new DjangoHttpClient();
