// eslint-disable-next-line project-structure/file-structure
import { Box, colors, styled } from '@goshippo/components';

import { SelectableArea } from '~/src/components/SelectableArea';

export const CenteredContentBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const CenteredBetweenContentBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DragDropSelectableArea = styled(SelectableArea)`
background-color: ${colors.backgroundChipBadge};
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 200px;
width: 100%;
text-align: center;
}`;

export const SummaryBox = styled(Box)`
background-color: ${colors.backgroundChipBadge};
display: flex;
flex-direction: column;
padding: 16px;
width: 100%;
border-radius: 8px;
}`;

export const LoadingArea = styled(Box)`
background-color: ${colors.backgroundChipBadge};
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-height: 200px;
width: 100%;
text-align: center;
}`;
