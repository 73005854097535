import { Dispatch, SetStateAction, createContext } from 'react';

export type SessionExpiredData = {
  sessionExpired: boolean;
  setSessionExpired: Dispatch<SetStateAction<boolean>>;
};

export const SessionExpiredContext = createContext<SessionExpiredData>({
  sessionExpired: false,
  setSessionExpired: () => {},
});
