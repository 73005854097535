import { Suspense, useEffect } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import {
  ShippoAuthenticationError,
  ShippoForbiddenError,
} from '~/src/clients/shippo/ShippoHttpClientErrors';
import { useSessionExpired } from '~/src/hooks/useSessionExpired';

const RETRY_ATTEMPTS = 3;

const defaultQueryClient = new QueryClient();

type Props = {
  client?: QueryClient;
};

export const QueryProvider: React.FC<Props> = ({ children, client = defaultQueryClient }) => {
  const { setSessionExpired } = useSessionExpired();

  useEffect(() => {
    client.setDefaultOptions({
      queries: {
        onError: async (error) => {
          if (error instanceof ShippoAuthenticationError) {
            setSessionExpired(true);
          }
        },
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          if (error instanceof ShippoAuthenticationError) return false;
          if (error instanceof ShippoForbiddenError) return false;

          if (failureCount >= RETRY_ATTEMPTS) return false;

          return true;
        },
      },
    });
  }, [client, setSessionExpired]);

  return (
    <Suspense fallback="">
      <QueryClientProvider client={client}>{children}</QueryClientProvider>
    </Suspense>
  );
};
