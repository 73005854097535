import { Component, type ErrorInfo, type FC, type ReactNode } from 'react';

type Props = {
  children?: ReactNode;
  errorComponent: FC<{ error: Error }>;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
};

type State = {
  error: Error | null;
};

export class MicroFrontendErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onError?.(error, errorInfo);
  }

  render() {
    if (!this.state.error) return this.props.children;

    const ErrorComponent = this.props.errorComponent;

    return <ErrorComponent error={this.state.error} />;
  }
}
