import { useEffect } from 'react';

import { setUser } from '@sentry/react';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutateFunction,
  useMutation,
  useQuery,
} from '@tanstack/react-query';

import { HttpClientError } from '~/src/clients/http/HttpClient';

import { UserAccount, userAccountService } from '../services/UserAccountService';

export const USER_ACCOUNT_CACHE_KEY = 'user_account';

export interface FetchUserReturnType {
  data: UserAccount | undefined;
  error: HttpClientError | null;
  isRefetching: boolean;
  isResendingEmail: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<UserAccount, HttpClientError>>;
  resendEmailVerification: UseMutateFunction<void, HttpClientError, void, unknown>;
  status: 'error' | 'loading' | 'success';
}

export const useUserAccount = (): FetchUserReturnType => {
  const { data, error, isRefetching, refetch, status } = useQuery<UserAccount, HttpClientError>({
    queryFn: userAccountService.get,
    queryKey: [USER_ACCOUNT_CACHE_KEY],
    staleTime: Infinity,
  });

  // sentry
  useEffect(() => {
    if (!data) return;
    setUser({ email: data.email, id: data.id, username: data.firstName });
  }, [data]);

  const { isLoading: isResendingEmail, mutate: resendEmailVerification } = useMutation<
    void,
    HttpClientError
  >({
    mutationFn: userAccountService.resendEmailVerification,
  });

  return { data, error, isRefetching, isResendingEmail, refetch, resendEmailVerification, status };
};
