import { Typography, colors } from '@goshippo/components';

import { Trans, useTranslation } from 'react-i18next';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, YAxis } from 'recharts';

import { MediumWeightText } from '~/src/components/Text';
import { formatAmount } from '~/src/utils/format';

import { RateCardSummary, totalCostGraphColors } from '../utils/types';
import { ChartContainer } from './ChartContainer';
import { SummaryBox } from './UploadRateCard.styled';

interface TotalCostChartProps {
  data: RateCardSummary[];
  rateCard1Name: string;
  rateCard1ServiceLevel: string;
  rateCard2Name: string;
  rateCard2ServiceLevel: string;
  totalShipments: number;
}

export const TotalCostChartReport = ({
  data,
  rateCard1Name,
  rateCard1ServiceLevel,
  rateCard2Name,
  rateCard2ServiceLevel,
  totalShipments,
}: TotalCostChartProps) => {
  const { t } = useTranslation('simulate', { keyPrefix: 'simulation' });

  const cheaperRateCardIndex =
    data.length > 1 ? (data[0].total_cost <= data[1].total_cost ? 0 : 1) : 0;

  const cheaperRateCard = cheaperRateCardIndex === 0 ? rateCard1Name : rateCard2Name;

  const otherRateCard = cheaperRateCardIndex === 0 ? rateCard2Name : rateCard1Name;

  const cheaperServiceLevel =
    cheaperRateCardIndex === 0 ? rateCard1ServiceLevel : rateCard2ServiceLevel;

  const otherServiceLevel =
    cheaperRateCardIndex === 0 ? rateCard2ServiceLevel : rateCard1ServiceLevel;

  const cheaperAmount = Math.abs(data[0].total_cost - data[1].total_cost);

  const cheaperPercent =
    cheaperRateCardIndex === 0
      ? (cheaperAmount / data[1].total_cost) * 100
      : (cheaperAmount / data[0].total_cost) * 100;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomLabel = (props: any) => {
    const { height, value, width, x, y } = props;
    const customY =
      height < 20 ? y - height / 2 : height < 40 && height > 20 ? y + height - 10 : y + 30;
    return (
      <text
        x={x + width / 2}
        y={customY}
        fontWeight="bold"
        fontSize={12}
        textAnchor="middle"
        fill={colors.text.default}
      >
        {formatAmount(value)}
      </text>
    );
  };

  return (
    <ChartContainer title={t('totalCost.title')}>
      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          data={data}
          margin={{
            bottom: 20,
            left: 35,
            right: 30,
            top: 5,
          }}
          style={{ alignSelf: 'center' }}
        >
          <YAxis
            tickLine={false}
            axisLine={false}
            label={{
              angle: -90,
              fill: `${colors.text.default}`,
              fontSize: '0.75rem',
              offset: 25,
              position: 'left',
              style: { textAnchor: 'middle' },
              value: t('totalCost.totalLabelCost'),
            }}
            tick={{ fill: `${colors.text.default}`, fontSize: '0.75rem' }}
            tickMargin={20}
          />
          <Bar dataKey="total_cost" radius={[8, 8, 0, 0]}>
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={totalCostGraphColors[index]} />
            ))}
            <LabelList dataKey="total_cost" content={<CustomLabel />} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <SummaryBox data-testid="total-cost-summary">
        <Typography component="p" variant="bodyEmphasized" mb={0.5}>
          {t('totalCost.summary')}
        </Typography>
        <Typography component="p" variant="body">
          <Trans components={{ strong: <MediumWeightText /> }}>
            {t('totalCost.summaryContext', {
              cheaperAmount: formatAmount(cheaperAmount),
              cheaperCard: cheaperRateCard,
              cheaperPercent: cheaperPercent.toFixed(1).toLocaleString(),
              cheaperServiceLevel,
              otherCard: otherRateCard,
              otherServiceLevel,
              totalShipments: totalShipments.toLocaleString(),
            })}
          </Trans>
        </Typography>
      </SummaryBox>
    </ChartContainer>
  );
};
