import { IconButton, OpenInNew, colors, shippoTheme, styled } from '@goshippo/components';

const OuterContainer = styled('div')`
  padding: ${shippoTheme.spacing(3)};
  border-radius: ${shippoTheme.spacing(1)};
  width: 100%;
  border: 1px solid ${colors.borders};
  display: flex;
  flex-direction: column;
  row-gap: ${shippoTheme.spacing(2)};
`;

const InnerContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: ${shippoTheme.spacing(1)};
`;

const Title = styled('h2')`
  font-size: 1.25rem;
  color: ${colors.text.default};
`;

interface ChartContainerProps {
  children: React.ReactNode;
  onClick?: () => void;
  title: string;
}

export const ChartContainer = ({ children, onClick, title }: ChartContainerProps) => (
  <OuterContainer>
    <InnerContainer>
      <Title sx={{ cursor: onClick ? 'pointer ' : 'default' }} onClick={onClick}>
        {title}
      </Title>

      {onClick && (
        <IconButton onClick={onClick}>
          <OpenInNew
            sx={{
              '& path': {
                fill: colors.secondary,
              },
            }}
          />
        </IconButton>
      )}
    </InnerContainer>
    {children}
  </OuterContainer>
);
