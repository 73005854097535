import { environment } from '~/src/utils/environment';

import { ShippoHttpClient } from './ShippoHttpClient';

export class PublicAPIClient extends ShippoHttpClient {
  constructor() {
    super(environment.SHIPPO_PUBLIC_API_BASE_URL);
  }
}

export const publicAPIClient = new PublicAPIClient();
