import React from 'react';

import FullScreenBanner from '@goshippo/libraries/shared/FullScreenBanner';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from '~/src/hooks/useSnackbar';
import { useUserAccount } from '~/src/hooks/useUserAccount';

export const FullScreenBannerWrapper = () => {
  const { data, resendEmailVerification } = useUserAccount();
  const { t } = useTranslation('', { keyPrefix: 'fullScreenBanner' });
  const navigate = useNavigate();
  const { show } = useSnackbar();

  const handleResendEmailVerification = () =>
    resendEmailVerification(undefined, {
      onError: (error) => {
        show({
          autoHideDuration: 3000,
          message: t('verifyEmail.snackbar.error', {
            message: error.message || 'Request Failed',
          }),
          variant: 'error',
        });
      },
      onSuccess: () => {
        show({
          autoHideDuration: 3000,
          message: t('verifyEmail.snackbar.success'),
          variant: 'success',
        });
      },
    });

  const handleGoToInvoices = React.useCallback(() => {
    navigate('/reports/invoices');
  }, [navigate]);

  if (data?.requiresEmailVerification) {
    return (
      <FullScreenBanner
        actionFn={handleResendEmailVerification}
        actionLabel={t('verifyEmail.actionLabel')}
        bannerType="notice"
        message={t('verifyEmail.message')}
      />
    );
  } else if (data?.hasFailedPayment) {
    return (
      <FullScreenBanner
        actionFn={handleGoToInvoices}
        actionLabel={t('pastDueInvoices.actionLabel')}
        bannerType="notice"
        message={t('pastDueInvoices.message')}
      />
    );
  }
  return null;
};
