import { Typography, TypographyProps, colors, styled } from '@goshippo/components';

const StyledTypography = styled(Typography)`
  font-size: 1rem;
  color: ${colors.text.default};
  display: inline;
`;

type TextProps = {
  component?: keyof JSX.IntrinsicElements;
} & Omit<TypographyProps, 'component'>;

export const Text = (props: TextProps) => {
  const { component = 'p', variant = 'body', ...propsRest } = props;

  return <StyledTypography {...propsRest} component={component} variant={variant} />;
};

export const MediumWeightText = styled('span')`
  font-weight: 500;
`;

/* Wrap text inside table that can overflow */
export const TableOverflowText = (props: TextProps) => (
  <Text
    {...props}
    sx={{
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: '2', // max 2 lines
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    }}
  />
);
