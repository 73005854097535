import { Box, Stack, shippoTheme, styled } from '@goshippo/components';

import { ReactComponent as ShippoLogoGreen } from '~/src/assets/logos/Shippo_logo.svg';

const OuterContainer = styled(Box)`
  background-color: #f3f7fa;
  min-height: 100vh;
`;

const InnerContainer = styled(Stack)`
  padding-top: 182px;
  margin: 0 auto;
  align-items: center;
`;

const OuterBox = styled(Box)`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 12px 40px rgba(0, 0, 0, 0.12);
  width: 410px;
  padding: ${shippoTheme.spacing(4)};
  text-align: center;
`;

const InnerBox = styled(Box)`
  width: 350px;
`;

export const Wrapper: React.FC = ({ children }) => (
  <OuterContainer>
    <InnerContainer spacing={shippoTheme.spacing(5)}>
      <ShippoLogoGreen style={{ width: '120px' }} />
      <OuterBox>
        <InnerBox>{children}</InnerBox>
      </OuterBox>
    </InnerContainer>
  </OuterContainer>
);
