import { useCallback } from 'react';

import Cookies from 'js-cookie';

import { environment } from '../utils/environment';

type LogoutFn = () => Promise<void>;

const COOKIE_OPTIONS = { domain: environment.SHIPPO_BASE_URL, path: '/' };

export const useLogout = (): LogoutFn => {
  const logout = useCallback(async () => {
    if (environment.LOCAL_AUTHENTICATION_ENABLED === 'true') {
      Cookies.remove('jwt', COOKIE_OPTIONS);
      Cookies.remove('is_impersonate', COOKIE_OPTIONS);
      location.reload();
      return;
    }

    location.href = `${environment.DJANGO_BASE_URL}/user/logout`;
  }, []);

  return logout;
};
