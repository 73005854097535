import {
  Event,
  EventHint,
  browserTracingIntegration,
  init,
  makeFetchTransport,
  makeMultiplexedTransport,
  moduleMetadataIntegration,
} from '@sentry/react';

import { environment } from '~/src/utils/environment';

export const setupSentry = () => {
  if (environment.SENTRY_ENABLED === 'false') return;

  init({
    beforeSend,
    denyUrls: DENY_URLS,
    dsn: environment.SENTRY_DSN,
    environment: environment.ENVIRONMENT_NAME,
    ignoreErrors: IGNORE_ERRORS,
    integrations: INTEGRATIONS,
    tracesSampleRate: 0.001,
    transport: makeMultiplexedTransport(makeFetchTransport, getDsnFromExtras),
  });
};

const IGNORE_ERRORS = [
  'TypeError: Load failed', // https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447,
  'ShippoHttpClientError: Request failed with status code 401', // https://shippo.atlassian.net/browse/PORTAL-542
];

const DENY_URLS = [
  // Google Adsense
  /pagead\/js/i,
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
];

const INTEGRATIONS = [
  browserTracingIntegration({
    tracePropagationTargets: ['localhost'],
  }),
  moduleMetadataIntegration(),
];

/**
 * This allows us to dynamically route issues to different projects. Used for the
 * Micro Frontend setup.
 *
 * For more details, read:
 * https://docs.sentry.io/platforms/javascript/configuration/micro-frontend-support
 */
const getDsnFromExtras = ({ getEvent }: { getEvent: () => Event | undefined }) => {
  const event = getEvent();
  const extra = event?.extra;

  if (!extra) return [];
  if (environment.SENTRY_ENABLED === 'false') return [];

  const dsn = (extra.dsn as string) || environment.SENTRY_DSN;
  const release = (extra.release as string) || 'unknown';

  return [{ dsn, release }];
};

const beforeSend = (event: Event, _hint: EventHint) => {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;
  if (!frames) return event;

  const framesWithMetadata = frames
    .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
    .map((v) => v.module_metadata);
  const metadata = framesWithMetadata[0];

  if (metadata) {
    event.extra = {
      ...event.extra,
      ...metadata,
    };
  }

  return event;
};
