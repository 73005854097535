import {
  AccordionDetails,
  AccordionSummary,
  ArrowForwardIosSharp,
  Accordion as BaseAccordion,
  shippoTheme,
  styled,
} from '@goshippo/components';

const Accordion = styled(BaseAccordion)`
  background: none;
  border: none;

  &.Mui-expanded {
    margin: 0;
  }
`;

const Summary = styled(AccordionSummary)`
  flex-direction: row-reverse;
  min-height: auto;

  &.Mui-expanded {
    min-height: auto;
  }

  .MuiAccordionSummary-contentGutters {
    margin: 0;
    &.Mui-expanded {
      margin: 0;
    }
  }

  .MuiAccordionSummary-expandIconWrapper {
    margin-right: ${shippoTheme.spacing(1)};
    &.Mui-expanded {
      transform: rotate(90deg);
    }
  }
`;

const Detail = styled(AccordionDetails)`
  padding: ${shippoTheme.spacing(1, 0)};
`;

const ExpandIcon = () => <ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />;

type Props = {
  className?: string;
  style?: React.CSSProperties;
  title: React.ReactNode;
};

export const Details: React.FC<Props> = ({ children, className, style, title }) => (
  <Accordion className={className} style={style}>
    <Summary expandIcon={<ExpandIcon />}>{title}</Summary>
    <Detail>{children}</Detail>
  </Accordion>
);
