import { useContext } from 'react';

import { SessionExpiredContext, SessionExpiredData } from '../contexts/SessionExpired';

export const useSessionExpired = (): SessionExpiredData => {
  const context = useContext(SessionExpiredContext);

  if (context === undefined) {
    throw new Error('`useSessionExpired` must be used within a `SessionExpiredProvider`');
  }

  return context;
};
