import { InvoicesType } from '../services/InvoiceService';

export const downloadReport = (body: string, fileName: string) => {
  const blob = new Blob([body], { type: 'application/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};

export const hideInvoiceReportButton = (invoice: InvoicesType) => {
  // hide button for open invoices
  if (invoice.status === 'OPEN') {
    return true;
  }

  const invoiceCreatedUtc = new Date(invoice.object_created).getTime(); // in ms
  const nowUtc = Date.now(); // in ms

  // hidding button for invoices created in the last 24 hours
  if (nowUtc - invoiceCreatedUtc <= 1000 * 60 * 60 * 24) {
    return true;
  }

  return false;
};
