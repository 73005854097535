import { Alert, AlertTitle, Button, colors, shippoTheme, styled } from '@goshippo/components';

import { useTranslation } from 'react-i18next';

import { HttpClientError } from '../clients/http/HttpClient';
import { Details } from './Details';

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Code = styled('pre')`
  font-size: 0.8rem;
  background: ${colors.gray10};
  padding: ${shippoTheme.spacing(1, 2)};
  border-radius: 8px;
`;

type Props = {
  error: HttpClientError;
  refetch?: () => void;
  title?: string;
};

export const TechnicalErrorAlert: React.VFC<Props> = ({ error, refetch, title }) => {
  const { t } = useTranslation('', { keyPrefix: 'technicalError' });

  return (
    <Alert severity="error">
      <TitleContainer>
        <AlertTitle>{title || t('title')}</AlertTitle>
        {refetch && (
          <Button color="error" onClick={refetch} size="small" variant="text">
            {t('retry')}
          </Button>
        )}
      </TitleContainer>

      <Details title={t('details')}>
        <Code>{error.message}</Code>
      </Details>
    </Alert>
  );
};
