import { z } from 'zod';

import { HttpClient } from '~/src/clients/http/HttpClient';
import { djangoHttpClient } from '~/src/clients/shippo/DjangoHttpClient';

const USER_ACCOUNT_SCHEMA = z.object({
  address: z
    .object({
      city: z.string(),
      company: z.string(),
      country: z.nullable(z.string()),
      email: z.string(),
      id: z.number(),
      is_default_return: z.boolean(),
      is_default_sender: z.boolean(),
      name: z.string(),
      object_created: z.string().nullable(),
      object_updated: z.string(),
      phone: z.string(),
      state: z.string(),
      street_no: z.string(),
      street1: z.string(),
      street2: z.string(),
      zip: z.string(),
    })
    .optional(),
  current_promo: z
    .object({
      amount: z.number(),
      code: z.string(),
      currency: z.string(),
      end_date: z.string(),
      max_uses: z.number(),
      min_qualify_units: z.number(),
      offer_text: z.string(),
      start_date: z.string(),
      status: z.string(),
      unit_type: z.number(),
      units: z.number(),
    })
    .nullable()
    .optional(),
  date_joined: z.string(),
  email: z.string().email(),
  first_name: z.string(),
  has_failed_payment: z.boolean(),
  has_purchased_label: z.boolean(),
  id: z.number(),
  invoice_adjustments: z.array(z.string()),
  is_account_owner: z.boolean(),
  is_active: z.boolean(),
  last_name: z.string(),
  lifetime_vol_segment_max: z.number(),
  lifetime_vol_segment_min: z.number(),
  login_user_id: z.number(),
  requires_email_verification: z.boolean(),
  revenue_tier_name: z.string(),
});

const UPDATE_USER_ACCOUNT_REQUEST_SCHEMA = z.object({
  email: z.string().email().optional(),
  name: z.string().optional(),
  new_password: z.string().optional(),
  new_password_confirmation: z.string().optional(),
  password: z.string(),
});

const PROFILE_USER_RESPONSE_SCHEMA = z.object({
  email: z.string().email(),
  first_name: z.string(),
  last_name: z.string(),
});

export type ApiUserAccount = z.infer<typeof USER_ACCOUNT_SCHEMA>;
type ApiUpdateUserAccountRequest = z.infer<typeof UPDATE_USER_ACCOUNT_REQUEST_SCHEMA>;
type ApiProfileUserResponse = z.infer<typeof PROFILE_USER_RESPONSE_SCHEMA>;

export type UserAccount = {
  address?: {
    city: string;
    company: string;
    country: null | string;
    email: string;
    id: number;
    isDefaultReturn: boolean;
    isDefaultSender: boolean;
    name: string;
    objectCreated: null | string;
    objectUpdated: string;
    phone: string;
    state: string;
    street1: string;
    street2: string;
    streetNo: string;
    zip: string;
  };
  creditCardConvenienceFee?: boolean; // true if invoice_adjustments contains 'CREDIT_CARD_CONVENIENCE_FEE'
  currentPromo?: {
    amount: number;
    code: string;
    currency: string;
    endDate: string;
    maxUses: number;
    minQualifyUnits: number;
    offerText: string;
    startDate: string;
    status: string;
    unitType: number;
    units: number;
  };
  dateJoined: string;
  email: string;
  firstName: string;
  hasFailedPayment: boolean;
  hasPurchasedLabel: boolean;
  id: number;
  isAccountOwner: boolean;
  isActive: boolean;
  lastName: string;
  lifetimeVolSegmentMax: number;
  lifetimeVolSegmentMin: number;
  loginUserId: number;
  requiresEmailVerification: boolean;
  revenueTierName: string;
};

export type UpdateUserAccount = {
  email?: string;
  name?: string;
  newPassword?: string;
  newPasswordConfirmation?: string;
  password: string;
};

export type ProfileUserAccount = {
  email: string;
  firstName: string;
  lastName: string;
};

export class UserAccountService {
  private parseProfileUserResponse = (body: ApiProfileUserResponse): ProfileUserAccount => ({
    email: body.email,
    firstName: body.first_name,
    lastName: body.last_name,
  });

  private parseUpdateUserAccountRequest = (
    data: UpdateUserAccount,
  ): ApiUpdateUserAccountRequest => ({
    email: data.email,
    name: data.name,
    new_password: data.newPassword,
    new_password_confirmation: data.newPasswordConfirmation,
    password: data.password,
  });

  private parseUserAccount = (body: ApiUserAccount): UserAccount => ({
    address: body.address
      ? {
          city: body.address.city,
          company: body.address.company,
          country: body.address.country,
          email: body.address.email,
          id: body.address.id,
          isDefaultReturn: body.address.is_default_return,
          isDefaultSender: body.address.is_default_sender,
          name: body.address.name,
          objectCreated: body.address.object_created,
          objectUpdated: body.address.object_updated,
          phone: body.address.phone,
          state: body.address.state,
          street1: body.address.street1,
          street2: body.address.street2,
          streetNo: body.address.street_no,
          zip: body.address.zip,
        }
      : undefined,
    creditCardConvenienceFee: body.invoice_adjustments.includes('CREDIT_CARD_CONVENIENCE_FEE'),
    currentPromo: body.current_promo
      ? {
          amount: body.current_promo.amount,
          code: body.current_promo.code,
          currency: body.current_promo.currency,
          endDate: body.current_promo.end_date,
          maxUses: body.current_promo.max_uses,
          minQualifyUnits: body.current_promo.min_qualify_units,
          offerText: body.current_promo.offer_text,
          startDate: body.current_promo.start_date,
          status: body.current_promo.status,
          unitType: body.current_promo.unit_type,
          units: body.current_promo.units,
        }
      : undefined,
    dateJoined: body.date_joined,
    email: body.email,
    firstName: body.first_name,
    hasFailedPayment: body.has_failed_payment,
    hasPurchasedLabel: body.has_purchased_label,
    id: body.id,
    isAccountOwner: body.is_account_owner,
    isActive: body.is_active,
    lastName: body.last_name,
    lifetimeVolSegmentMax: body.lifetime_vol_segment_max,
    lifetimeVolSegmentMin: body.lifetime_vol_segment_min,
    loginUserId: body.login_user_id,
    requiresEmailVerification: body.requires_email_verification,
    revenueTierName: body.revenue_tier_name,
  });

  get = async () => {
    const { body } = await this.httpClient.get<UserAccount>({
      url: '/v1/ui/account',
    });

    const apiUser = USER_ACCOUNT_SCHEMA.parse(body);

    return this.parseUserAccount(apiUser);
  };

  resendEmailVerification = async () => {
    // this endpoint always returns 200 with an empty body
    await this.httpClient.get({
      url: '/verify/resend/',
    });
  };

  updateUser = async (data: UpdateUserAccount) => {
    const { body } = await this.httpClient.put<ProfileUserAccount>({
      body: this.parseUpdateUserAccountRequest(data),
      url: '/ui/user/profile',
    });

    const apiUser = PROFILE_USER_RESPONSE_SCHEMA.parse(body);

    return this.parseProfileUserResponse(apiUser);
  };

  constructor(private readonly httpClient: HttpClient = djangoHttpClient) {}
}

export const userAccountService = new UserAccountService();
