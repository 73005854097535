import { useTranslation } from 'react-i18next';

import { DataTable } from '~/src/components/DataTable/DataTable';
import { formatAmount } from '~/src/utils/format';

import { RateCardSummary } from '../utils/types';

interface TotalCostTableReportProps {
  data: RateCardSummary[];
  rateCard1Name: string;
  rateCard2Name: string;
}

export const TotalCostTableReport = ({
  data,
  rateCard1Name,
  rateCard2Name,
}: TotalCostTableReportProps) => {
  const { t } = useTranslation('simulate', { keyPrefix: 'simulation.totalCost' });

  const convertToSingleRow = () => [
    { [data[0].name]: data[0].total_cost, [data[1].name]: data[1].total_cost, id: 'total_cost' },
  ];

  const columns = [
    {
      field: rateCard1Name,
      flex: 1,
      headerName: t('totalSpendRateCard', { rateCardName: rateCard1Name }),
      renderCell: () => formatAmount(data[0].total_cost),
      sortable: false,
    },
    {
      field: rateCard2Name,
      flex: 1,
      headerName: t('totalSpendRateCard', { rateCardName: rateCard2Name }),
      renderCell: () => formatAmount(data[1].total_cost),
      sortable: false,
    },
  ];

  return (
    <div data-testid="total-cost-table">
      <DataTable
        autoHeight
        columns={columns}
        getRowId={(row) => row.id}
        headerHeight={32}
        rows={convertToSingleRow()}
        status="success"
        hideFooter
      />
    </div>
  );
};
