import { createContext } from 'react';

import { AlertProps, SnackbarProps } from '@goshippo/components';

export type ShowSnackbarOptions = {
  action?: {
    label: React.ReactNode;
    onClick: () => void;
  };
  autoHideDuration?: SnackbarProps['autoHideDuration'];
  message: SnackbarProps['message'];
  onClose?: SnackbarProps['onClose'];
  position?: SnackbarProps['anchorOrigin'];
  variant?: AlertProps['severity'];
};

export type SnackbarContextData = {
  hide: () => void;
  show: (options: ShowSnackbarOptions) => void;
};

export const SnackbarContext = createContext<SnackbarContextData | undefined>(undefined);
