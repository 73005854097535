import axios, { AxiosError, AxiosInstance, AxiosResponse, CreateAxiosDefaults } from 'axios';

import {
  HttpClient,
  HttpClientError,
  HttpDeleteParams,
  HttpGetParams,
  HttpPatchParams,
  HttpPostParams,
  HttpPutParams,
  HttpResponse,
} from './HttpClient';

export type AxiosHttpClientOptions = CreateAxiosDefaults;
export const AxiosHttpClientError = AxiosError;

export class AxiosHttpClient implements HttpClient {
  protected instance: AxiosInstance;

  constructor(options?: AxiosHttpClientOptions) {
    this.instance = axios.create(options);
  }

  private handleError(error: unknown): never {
    if (error instanceof AxiosError) {
      throw new HttpClientError(
        error.message,
        error?.response?.status || error.status,
        error.response?.data,
      );
    }

    throw error;
  }

  async delete<T>(params: HttpDeleteParams): Promise<HttpResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.instance.delete(params.url, {
        headers: params.headers,
        params: params.query,
      });

      return {
        body: response.data,
        statusCode: response.status,
      };
    } catch (error) {
      this.handleError(error);
    }
  }

  async get<T>(params: HttpGetParams): Promise<HttpResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.instance.get(params.url, {
        headers: params.headers,
        params: params.query,
      });

      return {
        body: response.data,
        statusCode: response.status,
      };
    } catch (error) {
      this.handleError(error);
    }
  }

  async patch<T>(params: HttpPatchParams): Promise<HttpResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.instance.patch(params.url, params.body, {
        headers: params.headers,
        params: params.query,
      });

      return {
        body: response.data,
        statusCode: response.status,
      };
    } catch (error) {
      this.handleError(error);
    }
  }

  async post<T>(params: HttpPostParams): Promise<HttpResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.instance.post(params.url, params.body, {
        headers: params.headers,
        params: params.query,
      });

      return {
        body: response.data,
        statusCode: response.status,
      };
    } catch (error) {
      this.handleError(error);
    }
  }

  async put<T>(params: HttpPutParams): Promise<HttpResponse<T>> {
    try {
      const response: AxiosResponse<T> = await this.instance.put(params.url, params.body, {
        headers: params.headers,
        params: params.query,
      });

      return {
        body: response.data,
        statusCode: response.status,
      };
    } catch (error) {
      this.handleError(error);
    }
  }
}
