import {
  Box,
  DataGridPro,
  DataGridProProps,
  Stack,
  Typography,
  colors,
} from '@goshippo/components';

import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { RateComparisonCell } from '../components/RateComparisonCell';
import { rateCard1Color, rateCard2Color, rateCardEqualColor } from '../constants/rateCard';
import { RateCardComparison, WeightZoneComparisonRow } from '../services/SimulationService';
import { SimulationState } from '../utils/types';

type Columns = DataGridProProps<WeightZoneComparisonRow>['columns'];

type Props = {
  card1Name?: string;
  card2Name?: string;

  simulationResults: RateCardComparison | null;
  status: SimulationState;
};

export const RateCardComparisonResults = ({ simulationResults }: Props) => {
  const { t } = useTranslation('simulate', { keyPrefix: 'simulation.rateCardComparison' });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCellClassName = (params: any) => {
    const zoneMatch = params.field.match(/zone(\d+)/);
    // TODO - how to normalize this
    if (params.field === 'zoneAK') {
      const color = params.row.colors[9];
      return `zone-cell-${color.replace('#', '')}`;
    }
    if (params.field === 'zoneHI') {
      const color = params.row.colors[8];
      return `zone-cell-${color.replace('#', '')}`;
    }
    if (zoneMatch && zoneMatch[1]) {
      const zoneIndex = parseInt(zoneMatch[1], 10) - 2;
      const color = params.row.colors[zoneIndex + 1];
      return `zone-cell-${color.replace('#', '')}`;
    }
    return '';
  };
  const columns: Columns = [
    {
      align: 'center',
      field: 'weight',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableWeight'),
      renderCell: (params) => (
        <Typography component="p" variant="body">
          {params.row.weight}
        </Typography>
      ),
      sortable: false,
      width: 96,
    },
    {
      align: 'center',
      field: 'zone1',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZone1'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zone1}
          colorIndex={0}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zone2',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZone2'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zone2}
          colorIndex={1}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zone3',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZone3'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zone3}
          colorIndex={2}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zone4',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZone4'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zone4}
          colorIndex={3}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zone5',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZone5'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zone5}
          colorIndex={4}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zone6',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZone6'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zone6}
          colorIndex={5}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zone7',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZone7'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zone7}
          colorIndex={6}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zone8',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZone8'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zone8}
          colorIndex={7}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zoneHI',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZoneHI'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zoneHI}
          colorIndex={8}
        />
      ),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zoneAK',
      flex: 1,
      headerAlign: 'center',
      headerName: t('tableZoneAK'),
      renderCell: (params) => (
        <RateComparisonCell
          colors={params.row.colors}
          t={t}
          row={params.row.zoneAK}
          colorIndex={9}
        />
      ),
      sortable: false,
    },
  ];

  return (
    <Stack>
      <Box data-testid="rate-comparison-datatable" mt={2}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography
            component="p"
            variant="h3"
            mb={1}
            fontWeight={'700'}
            color={colors.text.secondary}
          >
            {t('destinationZone')}
          </Typography>
        </Box>
        <DataGridPro
          autoHeight
          columns={columns}
          getRowId={() => uuid()}
          headerHeight={32}
          rows={simulationResults?.results ?? []}
          rowHeight={simulationResults?.results.length === 0 ? 150 : 64}
          hideFooter
          disableColumnMenu
          disableColumnReorder
          disableColumnSelector
          disableSelectionOnClick
          sx={{
            ' .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.white,
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              '&:hover': {
                '& .row-highlight-bottom': {
                  bottom: '-1px',
                },
                '& .row-highlight-top': {
                  top: '-1px',
                },
                '& .row-highlight-top, & .row-highlight-bottom': {
                  backgroundColor: 'white',
                  content: '""',
                  height: '1px',
                  left: '-100vw',
                  position: 'absolute',
                  width: '200vw',
                  zIndex: 1,
                },
                '&::after': {
                  right: 0,
                },
                '&::before': {
                  left: 0,
                },
                '&::before, &::after': {
                  backgroundColor: 'white',
                  content: '""',
                  height: '200vh',
                  position: 'absolute',
                  top: '-100vh',
                  width: '1px',
                  zIndex: 99,
                },
              },
              borderBottom: 'none',
              color: colors.text.default,
              cursor: 'pointer',
              fontSize: '1rem',
              overflow: 'visible !important',
              position: 'relative',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: colors.text.secondary,
              fontWeight: 700,
            },
            '& .MuiDataGrid-root': {
              overflow: 'visible !important',
              position: 'relative',
            },

            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'unset',
            },
            [`& .MuiDataGrid-cell.zone-cell-${rateCard1Color.replace('#', '')}`]: {
              backgroundColor: rateCard1Color,
            },
            [`& .MuiDataGrid-cell.zone-cell-${rateCard2Color.replace('#', '')}`]: {
              ' & p ': { color: colors.white },
              backgroundColor: rateCard2Color,
            },
            [`& .MuiDataGrid-cell.zone-cell-${rateCardEqualColor.replace('#', '')}`]: {
              backgroundColor: colors.backgroundDeemphasized,
            },

            border: 'none',
            borderRadius: 0,
            overflow: 'visible',
          }}
          getCellClassName={getCellClassName}
        />
      </Box>
    </Stack>
  );
};
