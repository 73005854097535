import { useEffect, useMemo, useState } from 'react';

import Cookies from 'js-cookie';

import { newRelic } from '~/src/clients/newRelic/NewRelicClient';

import { useUserAccount } from './useUserAccount';

export const useNewRelicIdentifyEvent = () => {
  const { data } = useUserAccount();
  const [isIdentified, setIsIdentified] = useState(false);

  const identifyProps = useMemo(
    () => ({
      displayName: `${data?.firstName} ${data?.lastName}`,
      email: data?.email,
      id: data?.id,
      isAccountOwner: data?.isAccountOwner,
      loginUserId: data?.loginUserId,
    }),
    [data],
  );

  useEffect(() => {
    const isImpersonatingCookie = Cookies.get('is_impersonate') || '';
    const isImpersonating = isImpersonatingCookie.toLowerCase() === 'true';

    if (identifyProps?.id && !isIdentified && !isImpersonating) {
      newRelic.setUserId(`${identifyProps.id}`);
      newRelic.addPageAction('IDENTIFY_PORTAL_USER', identifyProps);
      setIsIdentified(true);
    }
  }, [isIdentified, identifyProps]);

  return null;
};
