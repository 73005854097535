import { environment } from '~/src/utils/environment';

export const setupTranscend = () => {
  if (environment.TRANSCEND_ENABLED === 'false') return;

  const script = document.createElement('script');
  script.src = environment.TRANSCEND_URL || '';
  script.setAttribute('data-cfasync', environment.TRANSCEND_CFA_SYNC);
  script.setAttribute('data-ui', environment.TRANSCEND_UI);
  script.setAttribute('data-css', environment.TRANSCEND_CSS);
  script.setAttribute('data-messages', environment.TRANSCEND_MESSAGES);
  script.setAttribute('data-sync-endpoint', environment.TRANSCEND_SYNC_ENDPOINT);
  document.body.appendChild(script);
};
