import { z } from 'zod';

import { HttpClient } from '~/src/clients/http/HttpClient';
import { publicAPIClient } from '~/src/clients/shippo/PublicAPIClient';

const REPORT_REQUEST_RESPONSE_SCHEMA = z.object({
  object_id: z.string(),
  status: z.enum(['QUEUED', 'PROCESSING', 'SUCCEEDED', 'FAILED']),
});

const ITEMIZED_REPORT_PARAMETERS_SCHEMA = z.object({
  invoice_id: z.string(), // invoice object id
});

const REPORT_STATUS_SCHEMA = z.object({
  created_at: z.string(),
  object_id: z.string(),
  parameters: ITEMIZED_REPORT_PARAMETERS_SCHEMA,
  status: z.enum(['SUCCEEDED', 'FAILED', 'PROCESSING', 'QUEUED']),
});

const REPORT_STATUSES_RESPONSE_SCHEMA = z.object({
  page_info: z.object({
    next: z.string().nullable(),
    prev: z.string().nullable(),
  }),
  resources: z.array(REPORT_STATUS_SCHEMA),
});

type ReportRequestResponse = z.infer<typeof REPORT_REQUEST_RESPONSE_SCHEMA>;
type ReportStatusesResponse = z.infer<typeof REPORT_STATUSES_RESPONSE_SCHEMA>;
type ApiReportStatus = z.infer<typeof REPORT_STATUS_SCHEMA>;

export type ReportStatus = {
  objectId: string;
  status: 'FAILED' | 'PROCESSING' | 'QUEUED' | 'SUCCEEDED';
};

export type ParametrizedReportStatus = {
  createdAt: string;
  invoiceId: string;
} & ReportStatus;

export type GetReportStatusesResponse = {
  next: null | string;
  prev: null | string;
  reports: ParametrizedReportStatus[];
};

export class ReportsService {
  // TODO: nice to have, add zod validation for body too look like a csv
  getDownloadUrl = async (reportId: string) => {
    const { body } = await this.httpClient.get({
      url: `/reports/itemized_invoice/requests/${reportId}/download-url`,
    });

    if (typeof body === 'string') return body;
  };

  getItemizedInvoiceReportStatus = async (reportId: string) => {
    const { body } = await this.httpClient.get<ApiReportStatus>({
      url: `/reports/itemized_invoice/requests/${reportId}`,
    });

    const apiReportStatus = REPORT_STATUS_SCHEMA.parse(body);

    return this.parseReportStatus(apiReportStatus);
  };

  getItemizedInvoiceReportStatuses = async (
    invoiceIds?: string[],
    page?: string, // Cursor like bmV4dDsyOzIwMjQtMDctMDhUMTc6MjY6MjcuOTE4NDQxKzAwOjAw
  ): Promise<GetReportStatusesResponse> => {
    const queryParams = {
      ...(invoiceIds && invoiceIds.length > 0 && { invoice_ids: invoiceIds.join() }),
      ...(page && { page }),
      limit: 50,
    };

    const { body } = await this.httpClient.get<ReportStatusesResponse>({
      query: queryParams,
      url: '/reports/itemized_invoice/requests',
    });

    const { page_info, resources } = REPORT_STATUSES_RESPONSE_SCHEMA.parse(body);

    return {
      next: page_info.next,
      prev: page_info.prev,
      reports: resources.map(this.parseReportStatus),
    };
  };

  parseReportStatus = (apiReportStatus: ApiReportStatus): ParametrizedReportStatus => {
    const { created_at, object_id, parameters, status } = apiReportStatus;

    return {
      createdAt: created_at,
      invoiceId: parameters.invoice_id,
      objectId: object_id,
      status,
    };
  };

  postItemizedInvoiceReportRequest = async (invoiceObjectId: string) => {
    const { body } = await this.httpClient.post<ReportRequestResponse>({
      body: { invoice_id: invoiceObjectId },
      url: '/reports/itemized_invoice/requests',
    });

    const { object_id, status } = REPORT_REQUEST_RESPONSE_SCHEMA.parse(body);

    return { objectId: object_id, status };
  };

  constructor(private readonly httpClient: HttpClient = publicAPIClient) {}
}

export const reportsService = new ReportsService();
