import { lazy } from 'react';

import { colors } from '@goshippo/components';

import { Navigate, Route, Routes } from 'react-router-dom';

import { NavigationMenuCategory } from '~/src/app/components/Layout/components/NavigationMenu';

import { ReactComponent as PackageIcon } from './assets/package_2.svg';
import Status from './pages/Status';

const Overview = lazy(() => import('./pages/Overview'));
const Labels = lazy(() => import('./pages/Transactions'));

export const ActivityRoutes = () => (
  <Routes>
    <Route element={<Overview />} path="overview" />
    <Route element={<Navigate to="overview" />} path="*" />
    <Route element={<Status />} path="status" />
    <Route element={<Labels />} path="labels" />
  </Routes>
);

export const activityNavigationCategory: NavigationMenuCategory = {
  icon: <PackageIcon fill={colors.secondary} />,
  items: [
    {
      name: 'activity.items.overview',
      route: '/activity/overview',
    },
    {
      name: 'activity.items.labels',
      route: '/activity/labels',
    },
    {
      name: 'activity.items.status',
      route: '/activity/status',
    },
  ],
  name: 'activity.title',
};
