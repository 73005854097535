import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useQuery,
} from '@tanstack/react-query';

import { HttpClientError } from '../clients/http/HttpClient';
import {
  CustomTypeEntitlement,
  QuantityTypeEntitlement,
  SwitchTypeEntitlement,
  UserEntitlements,
  userEntitlementService,
} from '../services/UserEntitlementService';

export type Entitlements = {
  hasCustomBranding: boolean;
  isStaff: boolean;
  numUserSeats: number;
  supportType: string;
};

interface UserEntitlementsReturnType {
  entitlements: Entitlements;
  loading: boolean;
  refetchEntitlements: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<UserEntitlements, HttpClientError>>;
}

export const USER_ENTITLEMENTS_CACHE_KEY = 'user_entitlements';

export const useUserEntitlements = (): UserEntitlementsReturnType => {
  const {
    data: entitlements,
    isLoading,
    refetch,
  } = useQuery<UserEntitlements, HttpClientError>({
    queryFn: userEntitlementService.get,
    queryKey: [USER_ENTITLEMENTS_CACHE_KEY],
    staleTime: 2000,
  });

  const userSeatsEntitlement = entitlements?.find(
    (entitlement): entitlement is QuantityTypeEntitlement => entitlement.token === 'user_seats',
  );

  const shippoStaffEntitlement = entitlements?.find(
    (entitlement): entitlement is SwitchTypeEntitlement => entitlement.token === 'shippo_staff',
  );

  const customBrandingEntitlement = entitlements?.some(
    (entitlement): entitlement is SwitchTypeEntitlement => entitlement.token === 'custom_branding',
  );

  const supportTypeEntitlement = entitlements?.find(
    (entitlement): entitlement is CustomTypeEntitlement => entitlement.token === 'support_type',
  );

  return {
    entitlements: {
      hasCustomBranding: Boolean(customBrandingEntitlement),
      isStaff: Boolean(shippoStaffEntitlement),
      numUserSeats: userSeatsEntitlement?.isUnlimited
        ? Infinity
        : userSeatsEntitlement?.quantity || 1,
      supportType: supportTypeEntitlement?.value || 'BASIC',
    },
    loading: isLoading,
    refetchEntitlements: refetch,
  };
};
