import { styled } from '@goshippo/components';

import Cookies from 'js-cookie';

import { environment } from '~/src/utils/environment';

const StopLink = styled('a')`
  text-decoration: none;
  width: 150px;
  position: fixed;
  padding: 10px 10px;
  z-index: 999;
  opacity: 0.8;
  left: 45%;
  top: 5px;

  color: #fff;
  background-color: #e76762;
  border: 2px solid #e76762;

  text-align: center;

  cursor: pointer;
  border-radius: 3px;
`;

export const StopImpersonateButton = () => {
  const isImpersonatingCookie = Cookies.get('is_impersonate') || '';

  if (isImpersonatingCookie.toLowerCase() === 'true') {
    return (
      <StopLink href={`${environment.DJANGO_BASE_URL}/impersonate/stop/`}>
        Stop Impersonating
      </StopLink>
    );
  }

  return null;
};
