import { Divider, Skeleton, shippoTheme, styled } from '@goshippo/components';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useLogout } from '~/src/hooks/useLogout';
import { useUserAccount } from '~/src/hooks/useUserAccount';
import { useUserEntitlements } from '~/src/hooks/useUserEntitlements';

import { Props, StyledMenu, StyledMenuItem, TruncatedText } from './NavigationBar';

const StyledDivider = styled(Divider)`
  margin: ${shippoTheme.spacing(2, 0)};
`;

const GreetingContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  padding: ${shippoTheme.spacing(3, 5, 0, 3)};
`;

export const AccountMenu: React.FC<Props> = ({ anchorEl, onCloseMenu, open }) => {
  const { data, status } = useUserAccount();

  const {
    entitlements: { numUserSeats },
  } = useUserEntitlements();
  // user management is only available if number of allowed seats > 1
  const showTeamMenu = numUserSeats > 1;

  const { t } = useTranslation('', { keyPrefix: 'navigationBar' });

  const navigate = useNavigate();
  const navigateTo = (path: string) => {
    navigate(path);
    onCloseMenu();
  };

  const plan = () => navigateTo('/account/plan');
  const profile = () => navigateTo('/account/profile');
  const manageTeams = () => navigateTo('/account/team');
  const billingSetup = () => navigateTo('/account/billing');
  const invoices = () => navigateTo('/account/invoices');
  const logout = useLogout();

  return (
    <StyledMenu
      MenuListProps={{
        'aria-labelledby': 'account-button',
      }}
      anchorEl={anchorEl}
      id="account-menu"
      onClose={onCloseMenu}
      open={open}
    >
      <GreetingContainer data-testid="nav-menu-greeting">
        <TruncatedText
          component="span"
          data-testid="nav-menu-hello"
          gutterBottom
          variant="bodyEmphasized"
        >
          {t('hi')},{' '}
          {status === 'loading' ? (
            <Skeleton
              aria-label={t('loadingAccount')}
              sx={{ display: 'inline-block' }}
              width="15ch"
            />
          ) : (
            data?.firstName
          )}
        </TruncatedText>
        <TruncatedText component="span" data-testid="nav-menu-email" gutterBottom variant="body">
          {status === 'loading' ? <Skeleton aria-label={t('loadingAccount')} /> : data?.email}
        </TruncatedText>
      </GreetingContainer>

      <StyledDivider variant="fullWidth" />

      <StyledMenuItem component="a" onClick={billingSetup}>
        <TruncatedText component="span">{t('billingSetup')}</TruncatedText>
      </StyledMenuItem>

      <StyledMenuItem component="a" onClick={invoices}>
        <TruncatedText component="span">{t('invoices')}</TruncatedText>
      </StyledMenuItem>

      <StyledMenuItem component="a" onClick={plan}>
        <TruncatedText component="span">{t('plan')}</TruncatedText>
      </StyledMenuItem>

      <StyledMenuItem component="a" onClick={profile}>
        <TruncatedText component="span">{t('profile')}</TruncatedText>
      </StyledMenuItem>

      {showTeamMenu && (
        <StyledMenuItem component="a" onClick={manageTeams}>
          <TruncatedText component="span">{t('manageTeams')}</TruncatedText>
        </StyledMenuItem>
      )}

      <StyledDivider variant="fullWidth" />

      <StyledMenuItem component="a" onClick={logout}>
        <TruncatedText component="span">{t('logout')}</TruncatedText>
      </StyledMenuItem>
    </StyledMenu>
  );
};
