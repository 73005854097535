import { Skeleton, shippoTheme, styled } from '@goshippo/components';

const Grid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: ${shippoTheme.spacing(4, 0, 1, 0)};
  gap: ${shippoTheme.spacing(2)};
  height: 400px;
`;

const Subgrid = styled('div')`
  display: grid;
  grid-template-rows: 1fr 2fr;
  gap: ${shippoTheme.spacing(2)};
`;

const RightAligned = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

type Props = React.HTMLAttributes<HTMLDivElement>;

export const DefaultScreenSkeleton: React.FC<Props> = (props) => (
  <div {...props}>
    <Skeleton height={50} />
    <Skeleton width={300} />
    <Skeleton width={200} />
    <Grid>
      <Skeleton height="100%" variant="rounded" />
      <Subgrid>
        <Skeleton height="100%" variant="rounded" />
        <Skeleton height="100%" variant="rounded" />
      </Subgrid>
    </Grid>
    <RightAligned>
      <Skeleton width={200} />
    </RightAligned>
  </div>
);
