import { colors, css, shippoTheme } from '@goshippo/components';

const CONTENT_DESKTOP_MAX_WIDTH = '1000px';
const NAVIGATION_MENU_DESKTOP_WIDTH = '320px';
const NAVIGATION_MENU_MOBILE_WIDTH = '270px';

export const container = css`
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  gap: ${shippoTheme.spacing(4)};
  overflow: hidden;

  ${shippoTheme.breakpoints.down('lg')} {
    gap: ${shippoTheme.spacing(2)};
  }

  ${shippoTheme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`;

export const aside = css`
  display: flex;
  flex: 0 0 ${NAVIGATION_MENU_DESKTOP_WIDTH};
  border-right: 1px solid ${colors.borders};

  ${shippoTheme.breakpoints.down('lg')} {
    flex: 0 0 ${NAVIGATION_MENU_MOBILE_WIDTH};
  }

  ${shippoTheme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const content = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow-y: auto;
`;

export const main = css`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  margin: ${shippoTheme.spacing(4, 4, 0, 0)};
  padding: ${shippoTheme.spacing(3, 0, 0, 3)};
  max-width: ${CONTENT_DESKTOP_MAX_WIDTH};
  width: 100%;

  ${shippoTheme.breakpoints.down('xl')} {
    max-width: 100%;
    width: auto;
  }

  ${shippoTheme.breakpoints.down('lg')} {
    margin: ${shippoTheme.spacing(1, 1, 0, 0)};
    padding: ${shippoTheme.spacing(1, 0, 0, 1)};
  }

  /* Spacing */
  &::after {
    content: '';
    min-height: ${shippoTheme.spacing(4)};

    ${shippoTheme.breakpoints.down('lg')} {
      min-height: ${shippoTheme.spacing(1)};
    }
  }
`;

export const sigmaStyles = css`
  padding: ${shippoTheme.spacing(7, 4, 0, 3)};
  max-width: unset;
  margin: 0;

  ${shippoTheme.breakpoints.down('lg')} {
    padding: ${shippoTheme.spacing(1, 1, 0, 1)};
  }
  &::after {
    content: '';
    min-height: ${shippoTheme.spacing(0)};

    ${shippoTheme.breakpoints.down('lg')} {
      min-height: ${shippoTheme.spacing(0)};
    }
  }
`;

export const navigationMenuContainer = css`
  display: flex;
  flex-direction: column;
  padding: ${shippoTheme.spacing(4, 0, 0, 4)};
  margin-top: ${shippoTheme.spacing(4)};
  width: 100%;
  overflow-y: auto;

  ${shippoTheme.breakpoints.down('lg')} {
    padding: ${shippoTheme.spacing(2, 0, 0, 1)};
    margin-top: ${shippoTheme.spacing(1)};
  }

  /* Spacing */
  &::after {
    content: '';
    min-height: ${shippoTheme.spacing(4)};

    ${shippoTheme.breakpoints.down('lg')} {
      min-height: ${shippoTheme.spacing(1)};
    }
  }
`;
