import { type FC } from 'react';

import { init, loadRemote } from '@module-federation/runtime';
import { type UserOptions } from '@module-federation/runtime/types';

import * as Framework from '.';

type InitMicroFrontendRemote = { defaultUrl: string; name: string };
type InitMicroFrontendOptions = {
  name: string;
  remotes: InitMicroFrontendRemote[];
  shared: UserOptions['shared'];
};

// Remote names can't include @, /, or -
const normalizeRemoteName = (name: string) =>
  name.replace('@', '').replace('/', '_').replace(/-/g, '_');

export const initMicroFrontend = ({ name, remotes, shared }: InitMicroFrontendOptions) =>
  init({
    name,
    remotes: generateRemotes(remotes),
    shared: {
      '@shippo/mfe-framework': {
        lib: () => Framework,
        version: '0.0.1',
      },
      ...shared,
    },
  });

export const loadMicroFrontend = (remote: string) => () => {
  const scope = normalizeRemoteName(remote.split('/').slice(0, 2).join('/'));
  const module = remote.split('/').slice(2).join('/');
  return loadRemote(`${scope}/${module}`) as Promise<{ default: FC }>;
};

const getDomainName = (hostname: string) =>
  hostname.substring(hostname.lastIndexOf('.', hostname.lastIndexOf('.') - 1) + 1);

const isSameDomain = (a: string, b: string) =>
  getDomainName(new URL(a).hostname) === getDomainName(new URL(b).hostname);

export const persistOverrideParamsToSession = () => {
  const searchParams = new URLSearchParams(location.search);
  searchParams.forEach((value, key) => {
    if (!key.startsWith('@shippo/mfe-')) return;
    if (!isSameDomain(location.href, value)) return;
    sessionStorage.setItem(key, value);
    searchParams.delete(key);
  });
  history.pushState(null, '', `?${searchParams.toString()}`);
};

// Generates a string that changes every 5 minutes, for cache busting purposes
const getCacheBustString = (): string => {
  const currentTime = Math.floor(Date.now() / 1000);
  const windowSize = 5 * 60;
  return `${Math.floor(currentTime / windowSize) * windowSize}`;
};

const generateRemotes = (remotes: InitMicroFrontendRemote[]) =>
  remotes.map((remote) => ({
    entry: `${
      sessionStorage.getItem(remote.name) || remote.defaultUrl
    }/remoteEntry.js?${getCacheBustString()}`,
    name: normalizeRemoteName(remote.name),
  }));
