type HttpHeaders = Record<string, boolean | number | string>;
type HttpQueryParameters = Record<string, boolean | number | string>;

export type HttpResponse<T> = {
  body?: T;
  statusCode: number;
};

export type HttpGetParams = {
  headers?: HttpHeaders;
  query?: HttpQueryParameters;
  url: string;
};

export type HttpDeleteParams = {
  headers?: HttpHeaders;
  query?: HttpQueryParameters;
  url: string;
};

export type HttpPostParams = {
  body?: FormData | Record<string, unknown>;
  headers?: HttpHeaders;
  query?: HttpQueryParameters;
  url: string;
};

export type HttpPatchParams = {
  body?: FormData | Record<string, unknown> | unknown[];
  headers?: HttpHeaders;
  query?: HttpQueryParameters;
  url: string;
};

export type HttpPutParams = HttpPostParams;

export interface HttpClient {
  delete: <T>(params: HttpDeleteParams) => Promise<HttpResponse<T>>;
  get: <T>(params: HttpGetParams) => Promise<HttpResponse<T>>;
  patch: <T>(params: HttpPatchParams) => Promise<HttpResponse<T>>;
  post: <T>(params: HttpPostParams) => Promise<HttpResponse<T>>;
  put: <T>(params: HttpPutParams) => Promise<HttpResponse<T>>;
}

export class HttpClientError extends Error {
  response?: Record<string, unknown>;
  status: number;

  constructor(message: string, status = 500, response?: Record<string, unknown>) {
    super(message);
    this.status = status;
    this.name = 'HttpClientError';
    this.response = response;
  }
}
