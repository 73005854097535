import { OptimizelyDecideOption, useDecision } from '@optimizely/react-sdk';

import { FeatureFlag } from './featureFlags';
import { DEFAULT_SPLIT_VARIATION, VariationTypes } from './variationTypes';

export interface FlagOptions {
  blockEvent?: boolean;
}

export const useFeatureFlag = (
  featureFlag: { variationType: typeof VariationTypes.ROLLOUT } & FeatureFlag,
  options?: FlagOptions,
) => {
  const [decision, clientReady] = useDecision(featureFlag.name, {
    autoUpdate: true,
    decideOptions: options?.blockEvent ? [OptimizelyDecideOption.DISABLE_DECISION_EVENT] : [],
  });

  if (!clientReady) {
    return null;
  }

  if (featureFlag.variationType === VariationTypes.ROLLOUT) {
    return { enabled: decision.variationKey === 'on' };
  }

  const variation =
    decision.variationKey === 'off' ? DEFAULT_SPLIT_VARIATION : decision.variationKey;

  return { variation };
};
