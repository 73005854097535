import { useState } from 'react';

import Cookies from 'js-cookie';

import { ShippoTokenContext } from '~/src/contexts/ShippoToken';

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = (props: Props) => {
  const { children } = props;

  const [shippoToken] = useState(Cookies.get('jwt'));

  return (
    <ShippoTokenContext.Provider value={shippoToken || undefined}>
      {children}
    </ShippoTokenContext.Provider>
  );
};
