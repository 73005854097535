import { DataGridPro, Skeleton, colors } from '@goshippo/components';

import { useTranslation } from 'react-i18next';

import { DataTableProps } from './DataTable';

type Props<T extends object> = Omit<DataTableProps<T>, 'status'>;

type WithId<T extends object> = { id: number } & T;

export const DataTableLoadingSkeleton = <T extends object>({
  loadingLabel,
  loadingRows = 10,
  ...dataGridProProps
}: Props<T>) => {
  const { t } = useTranslation('', { keyPrefix: 'dataTable' });

  const columns = dataGridProProps.columns.map((column) => ({
    ...column,
    renderCell: () => <Skeleton width="100%" />,
  }));

  const rows = [...Array(loadingRows).keys()].map((id) => ({ id }) as T) as T[];

  return (
    <div aria-label={loadingLabel || t('loading')}>
      <DataGridPro
        rowHeight={64}
        {...dataGridProProps}
        sx={{
          ' .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.gray20,
            borderBottom: `1px solid ${colors.gray30}`,
            borderRadius: 0,
          },
          '& .MuiDataGrid-cell, & .MuiDataGrid-cell:focus': {
            backgroundColor: 'unset',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: colors.text.secondary,
            fontWeight: 700,
          },

          borderLeft: 'none',
          borderRadius: 0,
          borderRight: 'none',
          borderTop: `1px solid ${colors.gray30}`,
        }}
        columns={columns}
        getRowId={(r) => (r as WithId<T>).id}
        rows={rows}
      />
    </div>
  );
};
