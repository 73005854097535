import { Route, Routes as RouterRoutes } from 'react-router-dom';

import { VerificationSuccess } from './EmailVerify/VerificationSuccess';
import { ProtectedRoutes } from './ProtectedRoutes';

export const Routes = () => (
  <RouterRoutes>
    {/* Public routes */}
    <Route element={<VerificationSuccess />} path="/verification_success" />

    {/* Protected routes */}
    <Route element={<ProtectedRoutes />} path="*" />
  </RouterRoutes>
);
