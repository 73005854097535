import { useEffect, useMemo, useState } from 'react';

import { useUserAccount } from './useUserAccount';

interface IdentifyProps {
  displayName?: string;
  email?: string;
  userId?: number;
}

export const usePortalAppcues = () => {
  const Appcues = window.Appcues;
  const [isIdentified, setIsIdentified] = useState(false);
  const { data, status } = useUserAccount();

  const identifyProps: IdentifyProps | null = useMemo(
    () => ({
      displayName: `${data?.firstName} ${data?.lastName}`,
      email: data?.email,
      userId: data?.id,
    }),
    [data],
  );

  useEffect(() => {
    if (Appcues && !isIdentified && identifyProps && status === 'success') {
      const { userId, ...restIdentifyProps } = identifyProps;
      Appcues.identify(String(userId), restIdentifyProps);

      setIsIdentified(true);
    }
  }, [isIdentified, identifyProps, Appcues, status]);
};
