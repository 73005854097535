import { createContext, useContext, type ReactNode } from 'react';

export type Token = string | undefined;
export type TokenType = 'Bearer' | 'JWT' | undefined;

// Authentication
export type MicroFrontendAuthentication = {
  getToken: () => Promise<Token> | Token;
  getTokenType: () => Promise<TokenType> | TokenType;
  handleSessionExpired: () => void;
};

// Snackbar
export type MicroFrontendSnackbarOptions = {
  action?: {
    label: ReactNode;
    onClick: () => void;
  };
  autoHideDuration?: number;
  message: ReactNode;
  onClose?: () => void;
  variant?: 'error' | 'info' | 'success' | 'warning';
};
export type MicroFrontendSnackbar = {
  show: (options: MicroFrontendSnackbarOptions) => void;
};

// Monitoring
type MicroFrontendCaptureExceptionOptions = { error: Error };
export type MicroFrontendMonitoring = {
  captureException: (options: MicroFrontendCaptureExceptionOptions) => void;
};

// Experimentation
export type MicroFrontendExperimentation = {
  getFeatureVariation: (feature: string) => string;
  isFeatureEnabled: (feature: string) => boolean;
};

// Analytics
type MicroFrontendAnalyticsTrackOptions = { name: string; properties?: Record<string, unknown> };
export type MicroFrontendAnalytics = {
  track: (options: MicroFrontendAnalyticsTrackOptions) => void;
};

type MicroFrontendContextData = {
  analytics: MicroFrontendAnalytics;
  authentication: MicroFrontendAuthentication;
  experimentation: MicroFrontendExperimentation;
  monitoring: MicroFrontendMonitoring;
  snackbar: MicroFrontendSnackbar;
};

const MicroFrontendContext = createContext<MicroFrontendContextData | null>(null);

export const MicroFrontendContextProvider = MicroFrontendContext.Provider;

export const useMicroFrontend = () => {
  const context = useContext(MicroFrontendContext);

  if (!context)
    throw new Error('`useMicroFrontend` must be used within a `MicroFrontendContextProvider`');

  return context;
};
