import { Dispatch, SetStateAction, createContext, useCallback, useContext, useState } from 'react';

import { QueryKey, UseQueryOptions, useQueries, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from '../hooks/useSnackbar';
import {
  ITEMIZED_INVOICE_REPORT_STATUS_CACHE_KEY,
  ITEMIZED_INVOICE_REPORT_STATUSES_CACHE_KEY,
} from '../views/Account/pages/Invoices/constants/reportsCacheKeys';
import {
  ParametrizedReportStatus,
  reportsService,
} from '../views/Account/pages/Invoices/services/ReportsService';
import { downloadReport } from '../views/Account/pages/Invoices/utils/reports';

export type ReportStatusContextData = {
  reportIds: string[];
  setReportIds: Dispatch<SetStateAction<string[]>>;
};

export const ReportStatusContext = createContext<ReportStatusContextData | undefined>(undefined);

type QueryOptions = UseQueryOptions<
  ParametrizedReportStatus,
  unknown,
  ParametrizedReportStatus,
  QueryKey
>;

export const ReportStatusProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('billing', { keyPrefix: 'reports' });
  const { show } = useSnackbar();
  const queryClient = useQueryClient();
  const [reportIds, setReportIds] = useState<string[]>([]);

  useQueries({
    queries: reportIds.map<QueryOptions>((reportId) => ({
      onSuccess: (data) => {
        if (data) {
          if (data.status === 'SUCCEEDED') {
            show({
              action: {
                label: 'Download',
                onClick: () =>
                  reportsService.getDownloadUrl(data.objectId).then((blob) => {
                    if (blob) {
                      const fileName = `itemized-invoice-${data.invoiceId}.csv`;
                      downloadReport(blob, fileName);
                      show({ message: t('downloadSuccess', { fileName }), variant: 'success' });
                    }
                  }),
              },
              message: t('invoiceSuccess'),
              variant: 'success',
            });
            setReportIds((prev) => prev.filter((id) => id !== reportId));
            queryClient.invalidateQueries([ITEMIZED_INVOICE_REPORT_STATUSES_CACHE_KEY]);
          } else if (data.status === 'FAILED') {
            show({ message: t('invoiceFailed'), variant: 'error' });
            setReportIds((prev) => prev.filter((id) => id !== reportId));
            queryClient.invalidateQueries([ITEMIZED_INVOICE_REPORT_STATUSES_CACHE_KEY]);
          }
        }
      },
      queryFn: () => reportsService.getItemizedInvoiceReportStatus(reportId),
      queryKey: [ITEMIZED_INVOICE_REPORT_STATUS_CACHE_KEY, reportId],
      refetchInterval: (data: ParametrizedReportStatus | undefined) => {
        // 30 seconds polling interval while we wait for the 1 minute backend databricks polling
        if (data && (data.status === 'PROCESSING' || data.status === 'QUEUED')) return 30000;
        else return false;
      },
      staleTime: Infinity,
    })),
  });

  const value = { reportIds, setReportIds };

  return <ReportStatusContext.Provider value={value}>{children}</ReportStatusContext.Provider>;
};

export const useReportStatusContext = () => {
  const context = useContext(ReportStatusContext);
  if (context === undefined) {
    throw new Error('useReportStatusContext must be used within a ReportStatusProvider');
  }

  const { reportIds, setReportIds } = context;

  const addReportToPoll = useCallback(
    (reportId: string) => {
      if (!reportIds.includes(reportId)) {
        setReportIds((prev) => [...prev, reportId]);
      }
    },
    [reportIds, setReportIds],
  );

  return { addReportToPoll };
};
