import { lazy } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

const FeatureA = lazy(() => import('./pages/FeatureA'));
const FeatureB = lazy(() => import('./pages/FeatureB'));

export const MicroFrontendRoutes = () => (
  <Routes>
    <Route element={<Navigate to="feature/a" />} index />
    <Route element={<FeatureA />} path="feature/a" />
    <Route element={<FeatureB />} path="feature/b" />
  </Routes>
);
