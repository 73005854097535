import { authTokenClient } from '../authToken/AuthTokenClient';
import { httpClient as httpClientInstance } from '../http';
import {
  HttpClient,
  HttpClientError,
  HttpDeleteParams,
  HttpGetParams,
  HttpPatchParams,
  HttpPostParams,
  HttpPutParams,
  HttpResponse,
} from '../http/HttpClient';
import {
  ShippoAuthenticationError,
  ShippoBadRequestError,
  ShippoForbiddenError,
  ShippoHttpClientError,
  ShippoInternalError,
} from './ShippoHttpClientErrors';

export class ShippoHttpClient implements HttpClient {
  constructor(
    private baseURL: string,
    private httpClient: HttpClient = httpClientInstance,
  ) {}

  private async mapParameters(params: HttpGetParams | HttpPostParams | HttpPutParams) {
    const tokenType = await authTokenClient.getTokenType();
    const authToken = await authTokenClient.getToken();

    return {
      ...params,
      headers: {
        ...params.headers,
        Authorization: `${tokenType} ${authToken}`,
      },
      url: this.baseURL + params.url,
    };
  }

  private mapShippoError(error: unknown) {
    if (!(error instanceof HttpClientError)) return error;

    const args: ConstructorParameters<typeof ShippoHttpClientError> = [
      error.message,
      error.status,
      error.response,
    ];

    if (error.status === 400) return new ShippoBadRequestError(...args);
    if (error.status === 401) return new ShippoAuthenticationError(...args);
    if (error.status === 403) return new ShippoForbiddenError(...args);
    if (error.status === 500) return new ShippoInternalError(...args);

    return new ShippoHttpClientError(...args);
  }

  async delete<T>(params: HttpDeleteParams): Promise<HttpResponse<T>> {
    try {
      return await this.httpClient.delete(await this.mapParameters(params));
    } catch (error) {
      throw this.mapShippoError(error);
    }
  }

  async get<T>(params: HttpGetParams): Promise<HttpResponse<T>> {
    try {
      return await this.httpClient.get(await this.mapParameters(params));
    } catch (error) {
      throw this.mapShippoError(error);
    }
  }

  async patch<T>(params: HttpPatchParams): Promise<HttpResponse<T>> {
    try {
      return await this.httpClient.patch(await this.mapParameters(params));
    } catch (error) {
      throw this.mapShippoError(error);
    }
  }

  async post<T>(params: HttpPostParams): Promise<HttpResponse<T>> {
    try {
      return await this.httpClient.post(await this.mapParameters(params));
    } catch (error) {
      throw this.mapShippoError(error);
    }
  }

  async put<T>(params: HttpPutParams): Promise<HttpResponse<T>> {
    try {
      return await this.httpClient.put(await this.mapParameters(params));
    } catch (error) {
      throw this.mapShippoError(error);
    }
  }
}
