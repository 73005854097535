import { useEffect, useMemo, useState } from 'react';

import { useFullStory } from '@goshippo/utilities';

import Cookies from 'js-cookie';

import { useUserAccount } from './useUserAccount';

export const usePortalFullStory = () => {
  const { identify, isInitialized } = useFullStory();
  const [isIdentified, setIsIdentified] = useState(false);
  const { data, status } = useUserAccount();

  const fullStoryIsInitialized = isInitialized();
  const isImpersonatingCookie = Cookies.get('is_impersonate') || '';
  const isImpersonating = isImpersonatingCookie.toLowerCase() === 'true';

  const identifyProps = useMemo(
    () => ({
      displayName: `${data?.firstName} ${data?.lastName}`,
      email: data?.email,
      id: data?.id,
    }),
    [data],
  );

  useEffect(() => {
    if (
      fullStoryIsInitialized &&
      !isIdentified &&
      identifyProps &&
      status === 'success' &&
      !isImpersonating
    ) {
      const { id, ...props } = identifyProps;
      identify(String(id), props);
      setIsIdentified(true);
    }
  }, [identify, fullStoryIsInitialized, isIdentified, identifyProps, status, isImpersonating]);

  return null;
};
