import { DataGridProProps } from '@goshippo/components';

import { useTranslation } from 'react-i18next';

import { DataTable } from '~/src/components/DataTable/DataTable';
import { formatAmount } from '~/src/utils/format';

import { rateBreakdownKeys } from '../services/SimulationService';
import { convertToCamelCase } from '../utils/Utils';
import { RateBreakdown, getTotalCostFromBreakdown } from '../utils/types';

interface Props {
  data: RateBreakdown[];
  rateCard1Name: string;
  rateCard2Name: string;
}

type SurchargeTableKeyType = 'total' | (typeof rateBreakdownKeys)[number];

type SurchargeTableDataType = {
  rateCard1: number;
  rateCard2: number;
  type: SurchargeTableKeyType;
};

export type SurchargeTableColumns = DataGridProProps<SurchargeTableDataType>['columns'];

export const SurchargeTableReport = ({ data, rateCard1Name, rateCard2Name }: Props) => {
  const { t } = useTranslation('simulate', { keyPrefix: 'simulation.surchargeBreakdown' });

  const convertBreakdownData = (): SurchargeTableDataType[] => {
    const convertedData: SurchargeTableDataType[] = [];

    rateBreakdownKeys.forEach((key) => {
      const row = {
        rateCard1: data[0][key],
        rateCard2: data[1][key],
        type: key,
      };
      convertedData.push(row);
    });

    const totalRow = {
      rateCard1: getTotalCostFromBreakdown(data[0]),
      rateCard2: getTotalCostFromBreakdown(data[1]),
      type: 'total' as SurchargeTableKeyType,
    };

    convertedData.push(totalRow);

    return convertedData;
  };

  const columns: SurchargeTableColumns = [
    {
      field: 'Type',
      flex: 1,
      headerName: t('type'),
      renderCell: (params) => t(convertToCamelCase(params.row.type)),
      sortable: false,
    },
    {
      field: 'Rate card 1',
      flex: 1,
      headerName: rateCard1Name,
      renderCell: (params) =>
        params.row.rateCard1 === 0 ? 'N/A' : formatAmount(params.row.rateCard1),
      sortable: false,
    },
    {
      field: 'Rate card 2',
      flex: 1,
      headerName: rateCard2Name,
      renderCell: (params) =>
        params.row.rateCard2 === 0 ? 'N/A' : formatAmount(params.row.rateCard2),
      sortable: false,
    },
  ];

  return (
    <div data-testid="surcharge-breakdown-table">
      <DataTable
        autoHeight
        columns={columns}
        getRowId={(row) => row.type}
        headerHeight={32}
        rows={convertBreakdownData()}
        status="success"
        hideFooter
      />
    </div>
  );
};
