type GetTokenTypeFn = () => Promise<string>;
type GetTokenFn = () => Promise<string | undefined>;

class AuthTokenClient {
  private _getTokenFn: GetTokenFn | null = null;
  private _getTokenTypeFn: GetTokenTypeFn | null = null;

  async getToken(): Promise<string | undefined> {
    if (!this._getTokenFn) {
      throw new Error('Missing `getToken` method');
    }

    return await this._getTokenFn();
  }

  async getTokenType(): Promise<string> {
    if (!this._getTokenTypeFn) {
      throw new Error('Missing `getTokenType` method');
    }

    return await this._getTokenTypeFn();
  }

  setGetTokenFn(getTokenFn: GetTokenFn) {
    this._getTokenFn = getTokenFn;
  }

  setGetTokenTypeFn(getTokenTypeFn: GetTokenTypeFn) {
    this._getTokenTypeFn = getTokenTypeFn;
  }
}

export const authTokenClient = new AuthTokenClient();
