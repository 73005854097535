import {
  AccountCircleOutlined,
  DescriptionOutlined,
  HelpOutline,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  colors,
  elevations,
  shippoTheme,
  styled,
} from '@goshippo/components';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as ShippoIcon } from '~/src/assets/icons/Shippo.svg';
import { useBasicMenu } from '~/src/hooks/useBasicMenu';

import { AccountMenu } from './AccountMenu';
import { DocumentationMenu } from './DocumentationMenu';
import { SupportMenu } from './SupportMenu';

const Header = styled('header')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background: #1a3722;
  padding: ${shippoTheme.spacing(2, 3)};

  ${shippoTheme.breakpoints.down('sm')} {
    padding: ${shippoTheme.spacing(1, 1.5)};
  }
`;

const NavigationContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: ${shippoTheme.spacing(2)};
`;

const NavigationIconButton = styled(IconButton)`
  color: ${colors.white};
  border-radius: ${shippoTheme.spacing(1)};

  &.active {
    background-color: ${colors.white}20;
    box-shadow: ${elevations[1]};
  }

  :hover {
    background-color: ${colors.white}20;
    box-shadow: ${elevations[2]};
  }
` as typeof IconButton;

export const StyledMenu = styled(Menu)`
  padding: 1rem;

  .MuiMenu-paper {
    border-radius: ${shippoTheme.spacing(1)};
  }

  ul {
    max-width: ${shippoTheme.spacing(35)};
    width: ${shippoTheme.spacing(35)};

    a {
      height: ${shippoTheme.spacing(5)};
      justify-content: flex-end;
      padding-right: ${shippoTheme.spacing(5)};
      margin-bottom: ${shippoTheme.spacing(1)};
    }

    li {
      display: flex;
      justify-content: flex-end;
      padding-right: ${shippoTheme.spacing(4)};
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  margin-bottom: ${shippoTheme.spacing(1)};
  padding-right: ${shippoTheme.spacing(5)};
  height: ${shippoTheme.spacing(5)};
  justify-content: flex-end;

  &:hover {
    background: ${colors.hoverFocus};
  }

  &.Mui-selected {
    background: ${colors.accent.background.success};
  }

  &:last-child {
    margin-bottom: ${shippoTheme.spacing(2)};
  }
` as typeof MenuItem;

export const TruncatedText = styled(Typography)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
`;

export type Props = {
  anchorEl: HTMLElement | null;
  onCloseMenu: () => void;
  open: boolean;
};

export const NavigationBar = () => {
  const { t } = useTranslation('', { keyPrefix: 'navigationBar' });
  const { anchorEl, handleCloseMenu, handleOpenMenu, open } = useBasicMenu();
  const {
    anchorEl: supportAnchor,
    handleCloseMenu: supportCloseMenu,
    handleOpenMenu: supportOpenMenu,
    open: supportOpen,
  } = useBasicMenu();
  const {
    anchorEl: docAnchor,
    handleCloseMenu: docCloseMenu,
    handleOpenMenu: docOpenMenu,
    open: docOpen,
  } = useBasicMenu();

  return (
    <Header>
      <Link to="/">
        <ShippoIcon style={{ color: 'white', width: '2.61rem' }} />
      </Link>

      <NavigationContainer>
        <Tooltip title={t('documentation')}>
          <div>
            <NavigationIconButton
              aria-controls={docOpen ? 'documentation-menu' : undefined}
              aria-expanded={docOpen ? 'true' : undefined}
              aria-haspopup="true"
              aria-label={t('documentation')}
              id="documentation-menu"
              onClick={docOpenMenu}
              size="large"
            >
              <DescriptionOutlined />
            </NavigationIconButton>
          </div>
        </Tooltip>

        <Tooltip title={t('support')}>
          <div>
            <NavigationIconButton
              aria-controls={supportOpen ? 'support-menu' : undefined}
              aria-expanded={supportOpen ? 'true' : undefined}
              aria-haspopup="true"
              aria-label={t('support')}
              id="support-menu"
              onClick={supportOpenMenu}
              size="large"
            >
              <HelpOutline />
            </NavigationIconButton>
          </div>
        </Tooltip>

        <Tooltip title={t('account')}>
          <div>
            <NavigationIconButton
              aria-controls={open ? 'account-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              aria-label={t('account')}
              id="account-button"
              onClick={handleOpenMenu}
              size="large"
            >
              <AccountCircleOutlined />
            </NavigationIconButton>
          </div>
        </Tooltip>

        <AccountMenu anchorEl={anchorEl} onCloseMenu={handleCloseMenu} open={open} />
        <DocumentationMenu anchorEl={docAnchor} onCloseMenu={docCloseMenu} open={docOpen} />
        <SupportMenu anchorEl={supportAnchor} onCloseMenu={supportCloseMenu} open={supportOpen} />
      </NavigationContainer>
    </Header>
  );
};
