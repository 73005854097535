import { useTranslation } from 'react-i18next';

import { menuLinks } from '~/src/utils/menu';

import { Props, StyledMenu, StyledMenuItem, TruncatedText } from './NavigationBar';

export const SupportMenu: React.FC<Props> = ({ anchorEl, onCloseMenu, open }) => {
  const { t } = useTranslation('', { keyPrefix: 'navigationBar' });

  const navigateTo = (path: string) => {
    window.open(path, '_blank');
    onCloseMenu();
  };

  const customerSupport = () => navigateTo(menuLinks.customerSupport);
  const contactSales = () => navigateTo(menuLinks.contactSales);

  return (
    <StyledMenu
      MenuListProps={{
        'aria-labelledby': 'support-button',
      }}
      anchorEl={anchorEl}
      id="support-menu"
      onClose={onCloseMenu}
      open={open}
    >
      <StyledMenuItem component="a" onClick={customerSupport} sx={{ mt: '1rem' }}>
        <TruncatedText component="span">{t('customerSupport')}</TruncatedText>
      </StyledMenuItem>

      <StyledMenuItem component="a" onClick={contactSales}>
        <TruncatedText component="span">{t('contactSales')}</TruncatedText>
      </StyledMenuItem>
    </StyledMenu>
  );
};
