import { useState } from 'react';

import { Alert, Button, Portal, Snackbar, SnackbarProps, Typography } from '@goshippo/components';

import { ShowSnackbarOptions, SnackbarContext } from '~/src/contexts/Snackbar';

const defaultOptions: ShowSnackbarOptions = {
  autoHideDuration: 6000,
  message: '',
  position: { horizontal: 'center', vertical: 'top' },
  variant: 'success',
};

type Props = {
  children: JSX.Element;
};

export const SnackbarProvider = (props: Props): JSX.Element => {
  const { children } = props;

  const [options, setOptions] = useState<ShowSnackbarOptions | null>(null);

  const autoHideDuration = options?.autoHideDuration || defaultOptions.autoHideDuration;
  const message = options?.message || defaultOptions.message;
  const position = options?.position || defaultOptions.position;
  const variant = options?.variant || defaultOptions.variant;
  const onClose = options?.onClose;
  const action = options?.action;

  const hide = () => {
    setOptions(null);
  };

  const show = (options: ShowSnackbarOptions) => {
    setOptions(options);
  };

  const handleClose: SnackbarProps['onClose'] = (event, reason) => {
    hide();
    onClose?.(event, reason);
  };

  return (
    <SnackbarContext.Provider value={{ hide, show }}>
      <>
        <Portal>
          <Snackbar
            key={new Date().toISOString()}
            {...defaultOptions}
            anchorOrigin={position}
            autoHideDuration={autoHideDuration}
            message={message}
            onClose={handleClose}
            open={!!options}
          >
            <div>
              <Alert onClose={(e) => handleClose(e, 'clickaway')} severity={variant}>
                <Typography component="p" variant="body">
                  {message}
                </Typography>

                {action && (
                  <Button color="secondary" onClick={action.onClick} size="small" variant="text">
                    {action.label}
                  </Button>
                )}
              </Alert>
            </div>
          </Snackbar>
        </Portal>

        {children}
      </>
    </SnackbarContext.Provider>
  );
};
