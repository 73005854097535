import { Button, Stack, Typography, shippoTheme } from '@goshippo/components';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text } from '~/src/components/Text';

import { Wrapper } from './components/Wrapper';

export const VerificationSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('', { keyPrefix: 'emailVerification' });
  return (
    <Wrapper>
      <Stack spacing={shippoTheme.spacing(2)}>
        <Typography component="h2" variant="h2">
          {t('verificationSuccess.title')}
        </Typography>
        <Text>{t('verificationSuccess.body')}</Text>
      </Stack>
      <Button
        onClick={() => navigate('/')}
        sx={{ fontSize: '0.875rem', mt: shippoTheme.spacing(3) }}
      >
        {t('verificationSuccess.continue')}
      </Button>
    </Wrapper>
  );
};
