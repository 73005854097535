import { Box, Grid, Stack, Typography, colors, shippoTheme } from '@goshippo/components';

import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';

import { MediumWeightText } from '~/src/components/Text';

import { rateBreakdownKeys } from '../services/SimulationService';
import { analyzeRateCards, getLastBreakdownIndex } from '../utils/Utils';
import {
  RateBreakdown,
  RateCardSummary,
  breakDownGraphColors,
  formatLegendText,
  getBreakdownPercentData,
} from '../utils/types';
import { SurchargeTableReport } from './SurchargeTableReport';
import { SummaryBox } from './UploadRateCard.styled';

interface RateBreakdownChartProps {
  data: RateBreakdown[];
  height?: number;
  rateCard1Name: string;
  rateCard1ServiceLevel: string;
  rateCard2Name: string;
  rateCard2ServiceLevel: string;
  rateCardSummaryData: RateCardSummary[];
  t: TFunction;
  totalShipments: number;
  width?: number;
}

export const SurchargeTotalChartFull = ({
  data,
  rateCard1Name,
  rateCard1ServiceLevel,
  rateCard2Name,
  rateCard2ServiceLevel,
  rateCardSummaryData,
  t,
  totalShipments,
}: RateBreakdownChartProps) => {
  // summary box varibles
  const {
    cheaperSummaryCard: { moreExpensiveCard, name, totalCostCheaper },
    highestFees: { cheaperFees, moreExpensiveFees },
  } = analyzeRateCards(rateCardSummaryData, data, rateCard1Name, rateCard2Name);

  const percentData = getBreakdownPercentData(data);

  const cheaperServiceLevel =
    name === rateCard1Name ? rateCard1ServiceLevel : rateCard2ServiceLevel;

  const otherServiceLevel = name === rateCard1Name ? rateCard2ServiceLevel : rateCard1ServiceLevel;

  // index of last rate breakdown for rate card 1
  const topBar1 = getLastBreakdownIndex(data[0]);

  // index of last rate breakdown for rate card 2
  const topBar2 = getLastBreakdownIndex(data[1]);

  // determine if the bar is the top bar to override the radius
  const isTopBar = (index: number, rateCardIndex: number): boolean =>
    rateCardIndex === 0 ? index === topBar1 : index === topBar2;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomizedLabel = (props: any) => {
    const { breakdownKey, height, name, value: percent, width, x, y } = props;

    const rateCardIndex = data.findIndex((d: RateBreakdown) => d.name === name);
    const textFill = breakdownKey === 'base' ? colors.white : colors.text.default;

    const price = t('simulation.rateCardComparison.price', {
      currency: 'USD',
      value: data[rateCardIndex][breakdownKey as keyof RateBreakdown],
    });

    const showBothLines = height >= 50;
    const customY = showBothLines
      ? height < 20
        ? y - height / 2
        : height < 40 && height > 20
        ? y + height - 10
        : y + 30
      : y + height / 2;

    // Dont display label if 0
    if (percent <= 0) {
      return null;
    }
    return (
      <text
        x={x + width / 2}
        y={customY}
        fill={textFill}
        fontWeight={700}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize={'0.75rem'}
      >
        {showBothLines ? (
          <>
            <tspan x={x + width / 2} dy="-0.6em">
              {percent}%
            </tspan>
            <tspan x={x + width / 2} dy="1.2em">
              {price}
            </tspan>
          </>
        ) : (
          <tspan x={x + width / 2} dy="0">
            {percent}% {price}
          </tspan>
        )}
      </text>
    );
  };
  return (
    <Stack spacing={shippoTheme.spacing(2)}>
      <Stack
        sx={{
          border: '1px solid',
          borderColor: colors.borders,
          borderRadius: '8px',
          padding: 3,
        }}
        display="flex"
        gap={shippoTheme.spacing(2)}
        data-testid="surcharge-breakdown-chart"
      >
        <Grid container>
          <Grid item xs={4} alignItems="center" display="flex" gap={shippoTheme.spacing(1)}>
            <Typography component="h1" fontWeight="bold" variant="h2">
              {t('simulation.surchargeBreakdown.title')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={8}
            alignItems="center"
            justifyContent="flex-end"
            display="flex"
            gap={shippoTheme.spacing(1)}
          >
            {rateBreakdownKeys.map((breakdown, index) => (
              <Stack alignItems="center" direction="row" gap={shippoTheme.spacing(1)} key={index}>
                <Box
                  sx={{
                    backgroundColor: breakDownGraphColors[index],
                    borderRadius: '8px',
                    height: '20px',
                    width: '20px',
                  }}
                />
                <Typography component="span" variant="caption">
                  {formatLegendText(breakdown)}
                </Typography>
              </Stack>
            ))}
          </Grid>
        </Grid>
        <ResponsiveContainer width="100%" height={450}>
          <BarChart
            data={percentData}
            margin={{
              bottom: 20,
              left: 35,
              right: 30,
              top: 20,
            }}
          >
            <XAxis
              dataKey="name"
              tickLine={false}
              axisLine={false}
              tick={{ dy: 20, fill: `${colors.text.default}`, fontSize: '1rem' }}
            />
            <YAxis
              tickLine={false}
              axisLine={false}
              ticks={[0, 25, 50, 75, 100]}
              domain={[0, 100]}
              label={{
                angle: -90,
                fill: `${colors.text.default}`,
                fontSize: '0.75rem',
                offset: 25,
                position: 'left',
                style: { textAnchor: 'middle' },
                value: t('simulation.surchargeBreakdown.percent'),
              }}
              tick={{ fill: `${colors.text.default}`, fontSize: '0.75rem' }}
            />
            {rateBreakdownKeys.map((breakdown, index) => (
              <Bar
                key={index}
                dataKey={breakdown}
                stackId="a"
                fill={breakDownGraphColors[index]}
                radius={[8, 8, 0, 0]}
              >
                <LabelList
                  dataKey={breakdown}
                  content={<CustomizedLabel breakdownKey={breakdown} />}
                />
                {data.map((_, rateCardIndex) => (
                  <Cell
                    key={`cell-${rateCardIndex}`}
                    fill={breakDownGraphColors[index]}
                    // override because cell radius applies to all 4 corners unlike bar radius
                    {...(!isTopBar(index, rateCardIndex) ? { radius: 0 } : {})}
                  />
                ))}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
        <SummaryBox>
          <Typography component="p" variant="bodyEmphasized" mb={0.5}>
            {t('simulation.rateCardComparison.summary')}
          </Typography>

          <Typography component="p" variant="body">
            <Trans components={{ strong: <MediumWeightText /> }}>
              {t('simulation.rateCardComparison.surchargeSummary', {
                cheaperFeeName: cheaperFees.name,
                cheaperFeePrice: cheaperFees.value,
                cheaperServiceLevel,
                formatParams: {
                  cheaperFeePrice: { currency: 'USD' },
                  moreExpensiveFeePrice: { currency: 'USD' },
                  price: { currency: 'USD' },
                },
                moreExpensiveCard,
                moreExpensiveFee: moreExpensiveFees.name,
                moreExpensiveFeePrice: moreExpensiveFees.value,
                name,
                otherServiceLevel,
                price: totalCostCheaper,
                totalShipments: totalShipments.toLocaleString(),
              })}
            </Trans>
          </Typography>
        </SummaryBox>
      </Stack>
      <SurchargeTableReport
        data={data}
        rateCard1Name={rateCard1Name}
        rateCard2Name={rateCard2Name}
      />
    </Stack>
  );
};
