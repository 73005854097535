import { useContext } from 'react';

import { SnackbarContext, SnackbarContextData } from '../contexts/Snackbar';

export const useSnackbar = (): SnackbarContextData => {
  const context = useContext(SnackbarContext);

  if (context === undefined) {
    throw new Error('`useSnackbar` must be used within a `SnackbarProvider`');
  }

  return context;
};
