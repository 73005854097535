import { useEffect, useState } from 'react';

import { Box, Grid, Stack, Typography, colors, shippoTheme } from '@goshippo/components';

import { Trans, useTranslation } from 'react-i18next';

import { MediumWeightText } from '~/src/components/Text';

import { ShipmentsByLocationChart } from './components/ShipmentsByLocationChart';
import { SurchargeTotalChartFull } from './components/SurchargeTotalChartFull';
import { TotalCostChartReport } from './components/TotalCostChartReport';
import { TotalCostTableReport } from './components/TotalCostTableReport';
import { SummaryBox } from './components/UploadRateCard.styled';
import { rateCard1Color, rateCard2Color } from './constants/rateCard';
import { RateCardComparison, ShipmentByLocation } from './services/SimulationService';
import { RateCardComparisonResults } from './tables/RateComparisonResults';
import {
  RateBreakdown,
  RateCardSummary,
  SimulationState,
  SimulationTypes,
  countGreenZones,
} from './utils/types';

const RateCardSimulationPageContext = () => {
  const [data, setData] = useState<RateCardComparison>();
  const [rateCard1Name, setRateCard1Name] = useState<string>('');
  const [rateCard2Name, setRateCard2Name] = useState<string>('');
  const [rateCard1ServiceLevel, setRateCard1ServiceLevel] = useState<string>('');
  const [rateCard2ServiceLevel, setRateCard2ServiceLevel] = useState<string>('');
  const [simulationType, setSimulationType] = useState<string>('');
  const [rateBreakdownChartData, setrateBreakdownChartData] = useState<RateBreakdown[]>([]);
  const [rateCardSummaryData, setRateCardSummaryData] = useState<RateCardSummary[]>([]);
  const [shipmentsByLocation, setShipmentsByLocation] = useState<ShipmentByLocation[]>([]);
  const [totalShipments, setTotalShipments] = useState<number>(0);
  const { t } = useTranslation('simulate');

  useEffect(() => {
    const storedData = sessionStorage.getItem('rateCardComparisonData');
    const rate1Name = sessionStorage.getItem('rateCard1Name');
    const rate2Name = sessionStorage.getItem('rateCard2Name');
    const rateCard1ServiceLevel = sessionStorage.getItem('rateCard1ServiceLevel');
    const rateCard2ServiceLevel = sessionStorage.getItem('rateCard2ServiceLevel');
    const simulationType = sessionStorage.getItem('simulationType');
    const rateBreakdownChartData = sessionStorage.getItem('rateBreakdownChartData');
    const rateCardSummary = sessionStorage.getItem('rateCardSummary');
    const totalShipments = sessionStorage.getItem('totalShipments');
    const shipmentsByLocation = sessionStorage.getItem('shipmentsByLocation');

    if (storedData) {
      setData(JSON.parse(storedData));
      setRateCard1Name(rate1Name || data?.rateCardFile1Name || '');
      setRateCard2Name(rate2Name || data?.rateCardFile2Name || '');
      setRateCard1ServiceLevel(rateCard1ServiceLevel || '');
      setRateCard2ServiceLevel(rateCard2ServiceLevel || '');
      setSimulationType(simulationType ?? '');
      setrateBreakdownChartData(rateBreakdownChartData ? JSON.parse(rateBreakdownChartData) : {});
      setRateCardSummaryData(rateCardSummary ? JSON.parse(rateCardSummary) : {});
      setShipmentsByLocation(shipmentsByLocation ? JSON.parse(shipmentsByLocation) : {});
    }

    if (totalShipments && !isNaN(Number(totalShipments))) {
      setTotalShipments(Number(totalShipments));
    }
  }, [data?.rateCardFile1Name, data?.rateCardFile2Name]);

  if (!data) return <div>{t('simulationError')}</div>;

  const { rateCard1Count, rateCard2Count, totalZones } = countGreenZones(data.results);
  const cheaperCard = rateCard1Count > rateCard2Count ? rateCard1Name : rateCard2Name;
  const otherCard = rateCard1Count > rateCard2Count ? rateCard2Name : rateCard1Name;

  const cheaperServiceLevel =
    rateCard1Count > rateCard2Count ? rateCard1ServiceLevel : rateCard2ServiceLevel;
  const otherServiceLevel =
    rateCard1Count > rateCard2Count ? rateCard2ServiceLevel : rateCard1ServiceLevel;

  const summaryCount = rateCard1Count > rateCard2Count ? rateCard1Count : rateCard2Count;
  const cheaperPercent = Math.round((summaryCount / totalZones) * 100);

  return (
    <Stack spacing={shippoTheme.spacing(2)} padding={2} maxWidth="1600px" alignSelf="center">
      <Box
        sx={{ border: '1px solid', borderColor: colors.borders, borderRadius: '8px', padding: 4 }}
      >
        <Grid container>
          <Grid item xs={2} alignItems="center" display="flex" gap={shippoTheme.spacing(1)}>
            <Typography component="h1" fontWeight="bold" variant="h2">
              {t('rateComparison')}
            </Typography>
          </Grid>

          <Grid
            item
            xs={10}
            alignItems="center"
            justifyContent="flex-end"
            display="flex"
            gap={shippoTheme.spacing(1)}
          >
            <Stack alignItems="center" direction="row" gap={shippoTheme.spacing(1)}>
              <Box
                sx={{
                  backgroundColor: rateCard1Color,
                  borderRadius: '8px',
                  height: '20px',
                  width: '20px',
                }}
              />
              <Typography component="span" variant="caption">
                {t('simulation.rateCardComparison.cheaperRateCard', {
                  name: rateCard1Name,
                })}
              </Typography>
            </Stack>
            <Stack alignItems="center" direction="row" gap={shippoTheme.spacing(1)}>
              <Box
                sx={{
                  backgroundColor: rateCard2Color,
                  borderRadius: '8px',
                  height: '20px',
                  width: '20px',
                }}
              />
              <Typography component="span" variant="caption">
                {t('simulation.rateCardComparison.cheaperRateCard', {
                  name: rateCard2Name,
                })}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <RateCardComparisonResults
          simulationResults={data}
          status={SimulationState.SUCCESS}
          card1Name={rateCard1Name}
          card2Name={rateCard2Name}
        />

        <SummaryBox mt={2}>
          <Typography component="p" variant="bodyEmphasized" mb={0.5}>
            {t('simulation.rateCardComparison.summary')}
          </Typography>
          <Typography component="p" variant="body">
            <Trans components={{ strong: <MediumWeightText /> }}>
              {t('simulation.rateCardComparison.summaryContext', {
                cheaperCard,
                cheaperPercent,
                cheaperServiceLevel,
                otherCard,
                otherServiceLevel,
                summaryCount,
              })}
            </Trans>
          </Typography>
        </SummaryBox>
      </Box>

      {/* Total label spend */}
      {simulationType === SimulationTypes.ServiceLevelCost && rateCardSummaryData && (
        <>
          <TotalCostChartReport
            data={rateCardSummaryData}
            totalShipments={totalShipments}
            rateCard1Name={rateCard1Name}
            rateCard2Name={rateCard2Name}
            rateCard1ServiceLevel={rateCard1ServiceLevel}
            rateCard2ServiceLevel={rateCard2ServiceLevel}
          />
          <TotalCostTableReport
            data={rateCardSummaryData}
            rateCard1Name={rateCard1Name}
            rateCard2Name={rateCard2Name}
          />
        </>
      )}

      {/* Rate breakdown */}
      {simulationType === SimulationTypes.ServiceLevelCost && (
        <SurchargeTotalChartFull
          rateCard1Name={rateCard1Name}
          rateCard2Name={rateCard2Name}
          rateCardSummaryData={rateCardSummaryData}
          data={rateBreakdownChartData}
          t={t}
          totalShipments={totalShipments}
          rateCard1ServiceLevel={rateCard1ServiceLevel}
          rateCard2ServiceLevel={rateCard2ServiceLevel}
        />
      )}

      {simulationType === SimulationTypes.ServiceLevelCost &&
        shipmentsByLocation.map((shipmentByLocation: ShipmentByLocation) => (
          <ShipmentsByLocationChart
            shipmentByLocation={shipmentByLocation}
            key={shipmentByLocation.source_location}
          />
        ))}
    </Stack>
  );
};

const RateCardSimulationPage = () => <RateCardSimulationPageContext />;

// eslint-disable-next-line import/no-default-export
export default RateCardSimulationPage;
