import { ServiceLevel, rateBreakdownKeys } from '../services/SimulationService';
import { RateBreakdown, RateCardSummary, ServiceRateCardNames, formatLegendText } from './types';

export const convertToCamelCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export const analyzeRateCards = (
  totalCostData: RateCardSummary[],
  breakdownData: RateBreakdown[],
  rateCard1Name: string,
  rateCard2Name: string,
) => {
  // Find the cheaper rate card
  const cheaperCard = totalCostData.reduce((a, b) => (a.total_cost < b.total_cost ? a : b));
  const moreExpensiveCard = totalCostData.reduce((a, b) => (a.total_cost > b.total_cost ? a : b));
  const cheaperCardName = cheaperCard.name === 'Rate Card 1' ? rateCard1Name : rateCard2Name;
  const moreExpensiveCardName =
    moreExpensiveCard.name === 'Rate Card 1' ? rateCard1Name : rateCard2Name;

  const rateCard1Fees = breakdownData.find(
    (breakdown) => breakdown.name === ServiceRateCardNames.RATE_CARD_1,
  );
  const rateCard2Fees = breakdownData.find(
    (breakdown) => breakdown.name === ServiceRateCardNames.RATE_CARD_2,
  );

  const rateCard1Highest = Object.entries(rateCard1Fees ?? [])
    ?.filter((fee) => fee[0] !== 'name' && fee[0] !== 'base')
    .reduce((a, b) => (a[1] > b[1] ? a : b));

  const rateCard2Highest = Object.entries(rateCard2Fees ?? [])
    ?.filter((fee) => fee[0] !== 'name' && fee[0] !== 'base')
    .reduce((a, b) => (a[1] > b[1] ? a : b));

  const cheaperCardsFees = cheaperCard.name === 'Rate Card 1' ? rateCard1Highest : rateCard2Highest;
  const moreExpensiveCardsFees =
    moreExpensiveCard.name === 'Rate Card 1' ? rateCard1Highest : rateCard2Highest;
  return {
    cheaperSummaryCard: {
      moreExpensiveCard: moreExpensiveCardName,
      name: cheaperCardName,
      totalCostCheaper: moreExpensiveCard.total_cost - cheaperCard.total_cost,
    },
    highestFees: {
      cheaperFees: { name: formatLegendText(cheaperCardsFees[0]), value: cheaperCardsFees[1] },
      moreExpensiveFees: {
        name: formatLegendText(moreExpensiveCardsFees[0]),
        value: moreExpensiveCardsFees[1],
      },
    },
  };
};

export const groupByCarriers = (serviceLevels: Map<string, ServiceLevel> | null) => {
  const groupedByCarrierMap = new Map<string, Map<string, ServiceLevel>>();
  serviceLevels?.forEach((serviceLevel, key) => {
    const { carrier } = serviceLevel;
    if (!groupedByCarrierMap.has(carrier)) {
      groupedByCarrierMap.set(carrier, new Map());
    }
    groupedByCarrierMap.get(carrier)!.set(key, serviceLevel);
  });

  return groupedByCarrierMap;
};

// finds the index of the last breakdown category to determine top bar in the chart
export const getLastBreakdownIndex = (data: RateBreakdown): number => {
  for (let i = rateBreakdownKeys.length - 1; i >= 0; i--) {
    if (data[rateBreakdownKeys[i]] > 0) {
      return i;
    }
  }
  return -1;
};

export const removeSessionStorageVariables = () => {
  sessionStorage.removeItem('rateCardComparisonData');
  sessionStorage.removeItem('rateCard1Name');
  sessionStorage.removeItem('rateCard2Name');
  sessionStorage.removeItem('rateCard1ServiceLevel');
  sessionStorage.removeItem('rateCard2ServiceLevel');
  sessionStorage.removeItem('simulationType');
  sessionStorage.removeItem('rateBreakdownChartData');
  sessionStorage.removeItem('rateCardSummary');
  sessionStorage.removeItem('totalShipments');
  sessionStorage.removeItem('shipmentsByLocation');
};
