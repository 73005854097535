import { useEffect, type ReactNode } from 'react';

import { OptimizelyProvider, ReactSDKClient, createInstance } from '@optimizely/react-sdk';

import { useUserDataForFeatureFlag } from '~/src/app/utils/featureflag/userUserDataForFeatureFlag';
import { environment } from '~/src/utils/environment';

declare global {
  interface Window {
    _optimizelyClient: ReactSDKClient | undefined;
  }
}

const WARNING = 3;
export const optimizelyClient = createInstance({
  datafileOptions: {
    autoUpdate: true,
  },
  eventBatchSize: 10,
  eventFlushInterval: 1000,
  logLevel: WARNING,
  sdkKey: environment.OPTIMIZELY_SDK_KEY,
});

/**
 * This provider component provides the Optimizely client to all child components via React context,
 * allowing them to access and use the Optimizely client for feature flag management.
 * The {children} prop is used to render any child components passed to this component.
 */
export const FeatureFlagsProvider = ({
  children,
  optimizelyClient,
}: {
  children?: ReactNode;
  optimizelyClient: ReactSDKClient;
}) => {
  const userData = useUserDataForFeatureFlag();

  useEffect(() => {
    const userChanged =
      (userData.type === 'anonymous') !== optimizelyClient?.user?.attributes?.anonymous;
    if (!userChanged) return;

    optimizelyClient.setUser(userData);
    window._optimizelyClient = optimizelyClient;
  }, [optimizelyClient, userData]);

  return <OptimizelyProvider optimizely={optimizelyClient}>{children}</OptimizelyProvider>;
};
