import { useEffect, useState } from 'react';

import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  shippoTheme,
  styled,
} from '@goshippo/components';
import { Dialog } from '@goshippo/libraries/Dialog';

import { zodResolver } from '@hookform/resolvers/zod';
import Cookies from 'js-cookie';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { useSnackbar } from '~/src/hooks/useSnackbar';
import { environment } from '~/src/utils/environment';

const FORM_ID = 'localAuthentication';

const LOCAL_AUTHENTICATION_FORM_SCHEMA = z
  .object({
    email: z.string().email().nonempty(),
    password: z.string().nonempty(),
  })
  .required();

type LocalAuthenticationFormSchema = z.infer<typeof LOCAL_AUTHENTICATION_FORM_SCHEMA>;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
  gap: ${shippoTheme.spacing(2)};
`;

/**
 * This component handles authentication for local development. It should never render in upper
 * environments.
 */
export const LocalAuthentication = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shippoToken] = useState(Cookies.get('jwt'));

  const { control, handleSubmit } = useForm<LocalAuthenticationFormSchema>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(LOCAL_AUTHENTICATION_FORM_SCHEMA),
  });

  const snackbar = useSnackbar();

  useEffect(() => {
    if (shippoToken) return;
    setShowDialog(true);
  }, [shippoToken]);

  const onSubmit = async ({ email, password }: LocalAuthenticationFormSchema) => {
    try {
      setIsSubmitting(true);

      const response = await fetch(`${environment.DJANGO_BASE_URL}/api/auth/login/`, {
        body: JSON.stringify({ email, password }),
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
      });

      if (response.status >= 400) throw new Error('Failed to login');
      location.reload();
    } catch (error) {
      let message = 'Failed to login';
      if (error instanceof Error) message = error.message;
      snackbar.show({ message, variant: 'error' });
      setIsSubmitting(false);
    }
  };

  if (environment.LOCAL_AUTHENTICATION_ENABLED === 'false') return null;

  return (
    <Dialog
      actions={[
        <Button
          color="primary"
          key={0}
          type="submit"
          form={FORM_ID}
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress aria-label="Logging in..." />}
        >
          Log in
        </Button>,
      ]}
      content={
        <StyledForm id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Alert severity="info" sx={{ mb: shippoTheme.spacing(1) }}>
            Logging in to{' '}
            <code>
              <strong>{environment.ENVIRONMENT_NAME}</strong>
            </code>
          </Alert>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Email"
                error={Boolean(error)}
                helperText={error?.message}
                onChange={onChange}
                value={value}
                type="email"
                name="email"
              />
            )}
            control={control}
            name="email"
          />
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                label="Password"
                onChange={onChange}
                error={Boolean(error)}
                helperText={error?.message}
                value={value}
                type="password"
                name="password"
                autoComplete="current-password"
              />
            )}
            control={control}
            name="password"
          />
        </StyledForm>
      }
      title={
        <Stack alignItems="center" direction="row" spacing={shippoTheme.spacing(1)}>
          <Typography component="h2" variant="h2">
            Local authentication
          </Typography>
        </Stack>
      }
      onClose={() => location.reload()}
      open={showDialog}
    />
  );
};
