import { useState } from 'react';

import { Button, Stack, Typography, shippoTheme } from '@goshippo/components';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Text } from '~/src/components/Text';
import { useUserAccount } from '~/src/hooks/useUserAccount';

import { Wrapper } from './components/Wrapper';

export const ResendVerification = () => {
  const { resendEmailVerification } = useUserAccount();
  const navigate = useNavigate();
  const { t } = useTranslation('', { keyPrefix: 'emailVerification' });

  const [showErrorMsg, setShowErrorMsg] = useState<boolean>(false);

  const handleResendEmailVerification = () =>
    resendEmailVerification(undefined, {
      onError: () => {
        setShowErrorMsg(true);
      },
      onSuccess: () => {
        navigate('/resend_verification/success');
      },
    });

  return (
    <Wrapper>
      <Stack spacing={shippoTheme.spacing(3)}>
        <Typography component="h2" variant="h2">
          {t('resendVerification.title')}
        </Typography>
        <Text>{t('resendVerification.body')}</Text>
        {showErrorMsg && <Text sx={{ color: '#e6735c' }}>{t('resendVerification.error')}</Text>}
      </Stack>

      <Button
        onClick={handleResendEmailVerification}
        sx={{ fontSize: '0.875rem', mt: shippoTheme.spacing(3) }}
      >
        {t('resendVerification.sendEmail')}
      </Button>
    </Wrapper>
  );
};
