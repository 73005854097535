import { Box, Stack, Typography } from '@goshippo/components';

import { useTranslation } from 'react-i18next';

export const Status = () => {
  const { t } = useTranslation('overview');

  return (
    <Stack>
      <Box>
        <Typography component="h1" fontWeight="bold" variant="h1">
          {t('status')}
        </Typography>
      </Box>
      <iframe
        height={'1000'}
        src="https://status.goshippo.com/"
        style={{ border: 'none', outline: 'none' }}
        title="API Status Page"
        width={'100%'}
      />
    </Stack>
  );
};
