import { Stack, Typography, colors } from '@goshippo/components';

import { Trans, useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

import { MediumWeightText } from '~/src/components/Text';

import { ShipmentByLocation } from '../services/SimulationService';
import { shipmentsByLocationGraphColor } from '../utils/types';
import { ChartContainer } from './ChartContainer';
import { ShipmentsByLocationTableReport } from './ShipmentsByLocationTableReport';
import { SummaryBox } from './UploadRateCard.styled';

type Props = {
  shipmentByLocation: ShipmentByLocation;
};

export const ShipmentsByLocationChart = ({ shipmentByLocation }: Props): JSX.Element => {
  const { t } = useTranslation('simulate', { keyPrefix: 'simulation' });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const CustomLabel = (props: any) => {
    const { height, value, width, x, y } = props;
    // Dont display label if 0
    const customValue = value > 0 ? value : '';
    // use height to set y for label
    const customY =
      height < 20 ? y - height / 2 : height < 40 && height > 20 ? y + height - 10 : y + 30;
    return (
      <text
        x={x + width / 2}
        y={customY}
        fontWeight="bold"
        fontSize={12}
        textAnchor="middle"
        fill={colors.text.default}
      >
        {customValue}
      </text>
    );
  };

  const getChartByLocation = (chartData: ShipmentByLocation) => {
    const zones = Object.entries(chartData.zones).map(([zoneName, value]) => ({
      name: t(`zonesAsNumbers.${zoneName}`),
      value,
    }));

    return (
      <Stack spacing={2}>
        <ChartContainer
          title={t('shipmentsByLocation.title', { sourceLocation: chartData.source_location })}
        >
          <ResponsiveContainer height={450}>
            <BarChart
              data={zones}
              margin={{
                bottom: 35,
                left: 20,
                right: 30,
                top: 5,
              }}
            >
              <XAxis
                tickLine={false}
                dataKey="name"
                tick={{ dy: 20, fill: `${colors.text.default}`, fontSize: '0.75rem' }}
              >
                <Label
                  value={t('shipmentsByLocation.XAxisLabel')}
                  position="bottom"
                  offset={20}
                  fill={colors.text.default}
                  fontSize={'0.75rem'}
                />
              </XAxis>
              <YAxis
                tickLine={false}
                axisLine={false}
                label={{
                  angle: -90,
                  fill: `${colors.text.default}`,
                  fontSize: '0.75rem',
                  position: 'left',
                  style: { textAnchor: 'middle' },
                  value: t('shipmentsByLocation.YAxisLabel'),
                }}
                tick={{ fill: `${colors.text.default}`, fontSize: '0.75rem' }}
              />
              <CartesianGrid horizontal vertical={false} stroke={colors.borders} />
              <Bar dataKey="value" radius={[8, 8, 0, 0]} fill={shipmentsByLocationGraphColor}>
                {zones.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={shipmentsByLocationGraphColor} />
                ))}
                <LabelList dataKey="value" content={<CustomLabel />} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <SummaryBox mt={3} data-testid="shipment-location-summary">
            <Typography component="p" variant="bodyEmphasized" mb={0.5}>
              {t('shipmentsByLocation.summary')}
            </Typography>
            <Typography component="p" variant="body">
              <Trans components={{ strong: <MediumWeightText /> }}>
                {t('shipmentsByLocation.summaryContext', {
                  countOver4: chartData.shipments_count_above_zone_4,
                  percentageOver4: Math.round(
                    (chartData.shipments_count_above_zone_4 / chartData.total_shipments) * 100,
                  ),
                  shipments: chartData.total_shipments,
                  sourceLocation: chartData.source_location,
                })}
              </Trans>
            </Typography>
          </SummaryBox>
        </ChartContainer>
        <ShipmentsByLocationTableReport shipmentByLocation={chartData} />
      </Stack>
    );
  };

  return shipmentByLocation && getChartByLocation(shipmentByLocation);
};
