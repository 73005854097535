import {
  ArrowBackIosNew,
  IconButton,
  Stack,
  Typography,
  colors,
  shippoTheme,
  styled,
} from '@goshippo/components';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router-dom';

const Container = styled('div')`
  border-bottom: 1px solid ${colors.borders};
  padding: ${shippoTheme.spacing(0.5)};

  ${shippoTheme.breakpoints.up('sm')} {
    display: none;
  }
`;

export const MobileMenuHeader = () => {
  const { t } = useTranslation();
  const location = useLocation();

  if (location.pathname === '/') return null;

  return (
    <Container>
      <IconButton component={RouterLink} disableRipple to="/">
        <Stack alignItems="center" direction="row" spacing={1}>
          <ArrowBackIosNew sx={{ color: colors.secondary, width: '1rem' }} />
          <Typography
            component="h3"
            sx={{ color: colors.text.primary.black }}
            variant="bodyEmphasized"
          >
            {t('backToMenu')}
          </Typography>
        </Stack>
      </IconButton>
    </Container>
  );
};
