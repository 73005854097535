import { z } from 'zod';

import { HttpClient } from '~/src/clients/http/HttpClient';
import { publicAPIClient } from '~/src/clients/shippo/PublicAPIClient';

import { DiscountType } from '../utils/types';

export const RATE_CARD_ROW_SCHEMA = z.object({
  id: z.number(),
  weight: z.number(),
  weight_unit: z.string(),
  zone1: z.number(),
  zone2: z.number(),
  zone3: z.number(),
  zone4: z.number(),
  zone5: z.number(),
  zone6: z.number(),
  zone7: z.number(),
  zone8: z.number(),
  zoneAK: z.number(),
  zoneHI: z.number(),
});

export const ORDER_HISTORY_ERROR_ROW_SCHEMA = z.object({
  carrier: z.string(),
  destination_first_address: z.string(),
  destination_second_address: z.string().nullable(),
  destination_state: z.string(),
  destination_zipcode: z.string(),
  height: z.union([z.number(), z.string()]),
  length: z.union([z.number(), z.string()]),
  service: z.string(),
  source_zipcode: z.string(),
  tracking_number: z.string(),
  weight: z.union([z.number(), z.string()]),
  width: z.union([z.number(), z.string()]),
});

export const ORDER_HISTORY_ROW_SCHEMA = ORDER_HISTORY_ERROR_ROW_SCHEMA.extend({
  address_type: z.string(),
  delivery_area: z.string().nullable().optional(),
  haversine_mi: z.number().nullable(),
  rating_zone: z.string().nullable(),
  volume: z.number(),
});

export const FILE_INGESTION_ERROR_ROW = z.object({
  errorType: z.string(),
  message: z.string(),
  row: ORDER_HISTORY_ERROR_ROW_SCHEMA,
});

export const RATE_CARD_SCHEMA = z.object({
  carrier: z.string(),
  data: z.array(RATE_CARD_ROW_SCHEMA),
  filename: z.string(),
  service_level: z.string(),
});

export const ORDER_HISTORY_SCHEMA = z.object({
  data: z.array(ORDER_HISTORY_ROW_SCHEMA),
  errors: z.array(FILE_INGESTION_ERROR_ROW),
  filename: z.string(),
});

export const APPLICABLE_FEE = z.object({
  base_fee: z.number(),
  name: z.string(),
});

export const SERVICE_LEVEL_SCHEMA = z.object({
  applicable_fees: z.array(APPLICABLE_FEE),
  carrier: z.string(),
  config_name: z.string(),
  dim_factor: z.number(),
  display_name: z.string(),
  id: z.number(),
});

export const SERVICE_LEVELS_SCHEMA = z.array(SERVICE_LEVEL_SCHEMA);

export const COLUMN_VALUES_SCHEMA = z.object({
  cheaper_rate: z.number(),
  percentage: z.number(),
  price: z.number(),
});

export const WEIGHT_ZONE_COMPARISON_ROW_SCHEMA = z.object({
  colors: z.array(z.string()),
  id: z.number(),
  weight: z.number(),
  weight_unit: z.string(),
  zone1: COLUMN_VALUES_SCHEMA,
  zone2: COLUMN_VALUES_SCHEMA,
  zone3: COLUMN_VALUES_SCHEMA,
  zone4: COLUMN_VALUES_SCHEMA,
  zone5: COLUMN_VALUES_SCHEMA,
  zone6: COLUMN_VALUES_SCHEMA,
  zone7: COLUMN_VALUES_SCHEMA,
  zone8: COLUMN_VALUES_SCHEMA,
  zoneAK: COLUMN_VALUES_SCHEMA,
  zoneHI: COLUMN_VALUES_SCHEMA,
});

const RATE_CARD_COMPARISON_SCHEMA = z.object({
  rate_card_file_1_name: z.string(),
  rate_card_file_2_name: z.string(),
  results: z.array(WEIGHT_ZONE_COMPARISON_ROW_SCHEMA),
});

const RATE_CARD_ORDER_HISTORY_COMPARISON_SUMMARY_SCHEMA = z.object({
  rate_card_1_percent_savings: z.number(),
  rate_card_1_savings: z.number(),
  rate_card_1_total_cost: z.number(),
  rate_card_2_total_cost: z.number(),
});

const RATE_CARD_ORDER_HISTORY_COMPARISON_RATE_CARD_BREAKDOWN_SCHEMA = z.object({
  base: z.number(),
  delivery_area_surcharge: z.number(),
  fuel_surcharge_rate: z.number(),
  residential_fee: z.number(),
});

const ZONE_COUNTS_SCHEMA = z.object({
  zone1: z.number(),
  zone2: z.number(),
  zone3: z.number(),
  zone4: z.number(),
  zone5: z.number(),
  zone6: z.number(),
  zone7: z.number(),
  zone8: z.number(),
  zoneAK: z.number(),
  zoneHI: z.number(),
});

const SHIPMENT_BY_LOCATION_SCHEMA = z.object({
  source_location: z.string(),
  total_shipments: z.number(),
  zones: ZONE_COUNTS_SCHEMA,
});

const RATE_CARD_ORDER_HISTORY_COMPARISON_BREAKDOWN_SCHEMA = z.object({
  rate_card_1: RATE_CARD_ORDER_HISTORY_COMPARISON_RATE_CARD_BREAKDOWN_SCHEMA,
  rate_card_2: RATE_CARD_ORDER_HISTORY_COMPARISON_RATE_CARD_BREAKDOWN_SCHEMA,
  shipments_by_location: z.array(SHIPMENT_BY_LOCATION_SCHEMA),
  total_shipments: z.number(),
});

const RATE_CARD_ORDER_HISTORY_COMPARISON_SCHEMA = z.object({
  breakdown: RATE_CARD_ORDER_HISTORY_COMPARISON_BREAKDOWN_SCHEMA,
  summary: RATE_CARD_ORDER_HISTORY_COMPARISON_SUMMARY_SCHEMA,
});

type ApiRateCard = z.infer<typeof RATE_CARD_SCHEMA>;
type ApiOrderHistory = z.infer<typeof ORDER_HISTORY_SCHEMA>;
type ApiRateCardComparison = z.infer<typeof RATE_CARD_COMPARISON_SCHEMA>;
type ApiRateCardOrderHistoryComparison = z.infer<typeof RATE_CARD_ORDER_HISTORY_COMPARISON_SCHEMA>;
type ApiShipmentByLocation = z.infer<typeof SHIPMENT_BY_LOCATION_SCHEMA>;
type ApiServiceLevel = z.infer<typeof SERVICE_LEVEL_SCHEMA>;
type ApiApplicableFee = z.infer<typeof APPLICABLE_FEE>;
type ApiZoneCounts = z.infer<typeof ZONE_COUNTS_SCHEMA>;

export type CreateRateCardComparison = {
  markup1: number;
  markup2: number;
  markupFormat: MarkupFormat;
  rateCard1: RateCard;
  rateCard1ServiceLevel: ServiceLevel;
  rateCard2: RateCard;
  rateCard2ServiceLevel: ServiceLevel;
};

type RateCardDiscounts = {
  delivery_area_surcharge: number;
  fuel_surcharge_rate: number;
  residential_fee: number;
};

export type RateCardMetadata = {
  dim_factor: number;
  discounts: RateCardDiscounts;
  serviceLevel: ServiceLevel;
};
// moving this around, cleaning up after adding carrier/sl to rate card
export type RateCardOrderHistoryComparisonRequest = {
  markup1: number;
  markup2: number;
  markupFormat: MarkupFormat;
  orderHistory: OrderHistory;
  rateCard1: RateCard;
  rateCard1Metadata: RateCardMetadata | null;
  rateCard2: RateCard;
  rateCard2Metadata: RateCardMetadata | null;
};

export type ColumnValues = {
  cheaper_rate: number;
  percentage: number;
  price: number;
};

export type WeightZoneComparisonRow = {
  colors: string[];
  id: number;
  weight: number;
  weight_unit: string;
  zone1: ColumnValues;
  zone2: ColumnValues;
  zone3: ColumnValues;
  zone4: ColumnValues;
  zone5: ColumnValues;
  zone6: ColumnValues;
  zone7: ColumnValues;
  zone8: ColumnValues;
  zoneAK: ColumnValues;
  zoneHI: ColumnValues;
};

export type RateCardRow = {
  id: number;
  weight: number;
  weight_unit: string;
  zone1: number;
  zone2: number;
  zone3: number;
  zone4: number;
  zone5: number;
  zone6: number;
  zone7: number;
  zone8: number;
  zoneAK: number;
  zoneHI: number;
};
export const zoneKeys: (keyof RateCardRow)[] = [
  'zone1',
  'zone2',
  'zone3',
  'zone4',
  'zone5',
  'zone6',
  'zone7',
  'zone8',
  'zoneAK',
  'zoneHI',
  'weight',
];

export type ZoneKey = Exclude<keyof WeightZoneComparisonRow, 'colors' | 'weight'>;

export type RateCard = {
  carrier: string;
  data: RateCardRow[];
  fileName: string;
  serviceLevel: string;
};

export enum MarkupFormat {
  PERCENTAGE = 'percentage',
  PRICE = 'price',
}

export type RateCardComparison = {
  rateCardFile1Name: string;
  rateCardFile2Name: string;
  results: WeightZoneComparisonRow[];
};

export type OrderHistoryRow = {
  address_type: string;
  carrier: string;
  delivery_area?: null | string;
  destination_first_address: string;
  destination_second_address: null | string;
  destination_state: string;
  destination_zipcode: string;
  haversine_mi: null | number;
  height: number | string;
  length: number | string;
  rating_zone: null | string;
  service: string;
  source_zipcode: string;
  tracking_number: string;
  volume: number;
  weight: number | string;
  width: number | string;
};

export type InvalidTypeWithCount = {
  count: number;
  invalidType: string;
};

export type FileIngestionReport = {
  invalidTypes: InvalidTypeWithCount[];
  rowsExcluded: number;
  rowsProcessed: number;
};

export type FileIngestionError = {
  errorType: string;
  message: string;
};

export type OrderHistory = {
  data: OrderHistoryRow[];
  fileName: string;
  fileReport?: FileIngestionReport;
};

export type ServiceLevel = {
  applicable_discounts: DiscountType[];
  carrier: string;
  config_name: string;
  dim_factor: number;
  display_name: string;
  id: number;
};

export type RateCardOrderHistoryComparisonSummary = {
  rate_card_1_percent_savings: number;
  rate_card_1_savings: number;
  rate_card_1_total_cost: number;
  rate_card_2_total_cost: number;
};

export type RateCardOrderHistoryComparisonRateCardBreakdown = {
  base: number;
  delivery_area_surcharge: number;
  fuel_surcharge_rate: number;
  residential_fee: number;
};

export type ZoneCounts = {
  zone1: number;
  zone2: number;
  zone3: number;
  zone4: number;
  zone5: number;
  zone6: number;
  zone7: number;
  zone8: number;
  zoneAK: number;
  zoneHI: number;
};

export type ShipmentByLocation = {
  shipments_count_above_zone_4: number;
  source_location: string;
  total_shipments: number;
  zones: ZoneCounts;
};

export const rateBreakdownKeys: (keyof RateCardOrderHistoryComparisonRateCardBreakdown)[] = [
  'base',
  'fuel_surcharge_rate',
  'residential_fee',
  'delivery_area_surcharge',
];

export type RateCardOrderHistoryComparisonBreakdown = {
  rate_card_1: RateCardOrderHistoryComparisonRateCardBreakdown;
  rate_card_2: RateCardOrderHistoryComparisonRateCardBreakdown;
};

export type RateCardOrderHistoryComparison = {
  breakdown: RateCardOrderHistoryComparisonBreakdown;
  shipments_by_location: ShipmentByLocation[];
  summary: RateCardOrderHistoryComparisonSummary;
  total_shipments: number;
};

export class SimulationService {
  private getShipmentCountsAboveZone4 = (zones: ApiZoneCounts): number =>
    zones.zone5 + zones.zone6 + zones.zone7 + zones.zone8 + zones.zoneAK + zones.zoneHI;

  private mapInvalidTypes = (errors: FileIngestionError[]): InvalidTypeWithCount[] => {
    const invalidTypes = new Set(errors.map((error: FileIngestionError) => error.errorType));
    const invalidCounts: InvalidTypeWithCount[] = [];
    invalidTypes.forEach((invalidType: string) => {
      invalidCounts.push({
        count: errors.filter((error: FileIngestionError) => error.errorType === invalidType).length,
        invalidType,
      });
    });
    return invalidCounts;
  };

  private parseOrderHistory = (apiOrderHistory: ApiOrderHistory): OrderHistory => ({
    data: apiOrderHistory.data,
    fileName: apiOrderHistory.filename,
    fileReport: {
      invalidTypes: this.mapInvalidTypes(apiOrderHistory.errors),
      rowsExcluded: apiOrderHistory.errors.length,
      rowsProcessed: apiOrderHistory.data.length,
    },
  });

  private parseRateCard = (apiRateCard: ApiRateCard): RateCard => ({
    carrier: apiRateCard.carrier,
    data: apiRateCard.data,
    fileName: apiRateCard.filename,
    serviceLevel: apiRateCard.service_level,
  });

  private parseRateCardComparison = (
    apiRateCardComparison: ApiRateCardComparison,
  ): RateCardComparison => ({
    rateCardFile1Name: apiRateCardComparison.rate_card_file_1_name,
    rateCardFile2Name: apiRateCardComparison.rate_card_file_2_name,
    results: apiRateCardComparison.results,
  });

  private parseRateCardOrderHistoryComparison = (
    apiRateCardOrderHistoryComparison: ApiRateCardOrderHistoryComparison,
  ): RateCardOrderHistoryComparison => {
    const { rate_card_1, rate_card_2 } = apiRateCardOrderHistoryComparison.breakdown;

    return {
      breakdown: {
        rate_card_1,
        rate_card_2,
      },
      shipments_by_location: apiRateCardOrderHistoryComparison.breakdown.shipments_by_location.map(
        (apiShipmentByLocation: ApiShipmentByLocation) =>
          this.parseShipmentsByLocation(apiShipmentByLocation),
      ),
      summary: apiRateCardOrderHistoryComparison.summary,
      total_shipments: apiRateCardOrderHistoryComparison.breakdown.total_shipments,
    };
  };

  private parseServiceLevel = (apiServiceLevel: ApiServiceLevel): ServiceLevel => ({
    applicable_discounts: apiServiceLevel.applicable_fees.map(
      (fee: ApiApplicableFee) => fee.name as DiscountType,
    ),
    carrier: apiServiceLevel.carrier,
    config_name: apiServiceLevel.config_name,
    dim_factor: apiServiceLevel.dim_factor,
    display_name: apiServiceLevel.display_name,
    id: apiServiceLevel.id,
  });

  private parseShipmentsByLocation = (
    apiShipmentByLocation: ApiShipmentByLocation,
  ): ShipmentByLocation => ({
    shipments_count_above_zone_4: this.getShipmentCountsAboveZone4(apiShipmentByLocation.zones),
    source_location: apiShipmentByLocation.source_location,
    total_shipments: apiShipmentByLocation.total_shipments,
    zones: apiShipmentByLocation.zones,
  });

  createRateCardComparisonSimulation = async (
    args: CreateRateCardComparison,
  ): Promise<RateCardComparison> => {
    const { body } = await this.httpClient.post({
      // have to add file name to context, until then, will pass filename from RateCard so it doesnt get mad
      body: {
        markup_1: args.markup1,
        markup_2: args.markup2,
        markup_format: args.markupFormat,
        rate_card_1: {
          carrier: args.rateCard1ServiceLevel.carrier,
          data: args.rateCard1.data,
          filename: args.rateCard1.fileName,
          service_level: args.rateCard1ServiceLevel.config_name,
        },
        rate_card_2: {
          carrier: args.rateCard2ServiceLevel.carrier,
          data: args.rateCard2.data,
          filename: args.rateCard2.fileName,
          service_level: args.rateCard2ServiceLevel.config_name,
        },
      },
      headers: { 'Content-Type': 'application/json' },
      url: `/simulation/rate_card_comparison`,
    });
    const apiRateCardComparison = RATE_CARD_COMPARISON_SCHEMA.parse(body);
    return this.parseRateCardComparison(apiRateCardComparison);
  };

  createRateCardOrderHistorySimulation = async (
    args: RateCardOrderHistoryComparisonRequest,
  ): Promise<RateCardOrderHistoryComparison> => {
    const { body } = await this.httpClient.post({
      body: {
        markup_1: args.markup1,
        markup_2: args.markup2,
        markup_format: args.markupFormat,
        order_history: { data: args.orderHistory.data, filename: args.orderHistory.fileName },
        rate_card_1: {
          carrier: args?.rateCard1Metadata?.serviceLevel.carrier,
          data: args.rateCard1.data,
          filename: args.rateCard1.fileName,
          service_level: args?.rateCard1Metadata?.serviceLevel.config_name,
        },
        rate_card_1_metadata: {
          ...args.rateCard1Metadata,
          service_level: args?.rateCard1Metadata?.serviceLevel.config_name,
        },
        rate_card_2: {
          carrier: args?.rateCard2Metadata?.serviceLevel.carrier,
          data: args.rateCard2.data,
          filename: args.rateCard2.fileName,
          service_level: args?.rateCard2Metadata?.serviceLevel.config_name,
        },
        rate_card_2_metadata: {
          ...args.rateCard2Metadata,
          service_level: args?.rateCard2Metadata?.serviceLevel.config_name,
        },
      },
      headers: { 'Content-Type': 'application/json' },
      url: `/simulation/rate_card_order_history_comparison`,
    });
    const apiRateCardOrderHistoryComparison = RATE_CARD_ORDER_HISTORY_COMPARISON_SCHEMA.parse(body);
    return this.parseRateCardOrderHistoryComparison(apiRateCardOrderHistoryComparison);
  };

  getServiceLevels = async (): Promise<ServiceLevel[]> => {
    const { body } = await this.httpClient.get({
      url: '/simulation/service_levels',
    });
    const apiServiceLevels = SERVICE_LEVELS_SCHEMA.parse(body);
    return apiServiceLevels.map((serviceLevel: ApiServiceLevel) =>
      this.parseServiceLevel(serviceLevel),
    );
  };

  uploadOrderHistory = async (formData: FormData): Promise<OrderHistory> => {
    const { body } = await this.httpClient.post({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      body: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      url: `/simulation/asset/order_history`,
    });
    const apiOrderHistory = ORDER_HISTORY_SCHEMA.parse(body);
    return this.parseOrderHistory(apiOrderHistory);
  };

  uploadRateCard = async (formData: FormData): Promise<RateCard> => {
    const { body } = await this.httpClient.post({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      body: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
      url: `/simulation/asset/rate_card`,
    });
    const apiRateCard = RATE_CARD_SCHEMA.parse(body);
    return this.parseRateCard(apiRateCard);
  };

  constructor(private readonly httpClient: HttpClient = publicAPIClient) {}
}

export const simulationService = new SimulationService();
