import { Box, Skeleton } from '@goshippo/components';

export const FullWidthLayoutSkeleton: React.FC = () => (
  <Box
    sx={{
      bgcolor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      width: '100vw',
    }}
  >
    <Skeleton variant="rectangular" width="100%" height={64} animation="wave" />

    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Skeleton variant="rectangular" width="100%" height="100%" animation="wave" />
    </Box>
  </Box>
);
