import { ButtonHTMLAttributes } from 'react';

import { colors, elevations, shippoTheme, styled } from '@goshippo/components';

export type Props = {
  selected?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = styled('button')`
  display: flex;
  flex: 1;

  gap: ${shippoTheme.spacing(1)};
  padding: ${shippoTheme.spacing(1.5, 2)};

  border-style: solid;
  border-color: ${colors.borders};
  border-radius: ${shippoTheme.spacing(1)};
  border-width: 1px;

  background: ${colors.white};
  color: ${colors.text.default};

  text-align: left;
  font-size: 1rem;

  cursor: pointer;
  transition: box-shadow ${shippoTheme.transitions.duration.shortest}ms;

  &:hover {
    box-shadow: ${elevations[2]};
  }

  &:focus {
    outline: 4px solid #26d990;
  }

  &[aria-pressed='true'] {
    background: ${colors.selectedBackground};
    border-color: ${colors.primary};
    border-width: 2px;
  }

  &:disabled {
    color: #425b66;
    background: ${colors.backgroundDeemphasized};
    border-color: ${colors.secondaryDisabled};
    border-width: 2px;
    box-shadow: none;
  }
`;

export const SelectableArea = ({ children, selected, ...attributes }: Props) => (
  <Button {...attributes} aria-pressed={selected ? 'true' : 'false'}>
    {children}
  </Button>
);
