import { Skeleton, styled } from '@goshippo/components';

import { useTranslation } from 'react-i18next';

import { aside, container, content, main, navigationMenuContainer } from '../shared/layoutStyles';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
`;

const NavigationBar = styled(Skeleton)`
  height: 80px;
`;

const Container = styled('div')`
  ${container}
  flex: 1 0 100%;
`;

const Aside = styled('aside')`
  ${aside}
`;

const Content = styled('div')`
  ${content}
`;

const Main = styled('main')`
  ${main}
`;

const NavigationMenuContainer = styled('div')`
  ${navigationMenuContainer}
`;

const MenuCategory = styled('div')`
  padding-left: 56px;
  margin-bottom: 20px;
`;

const MenuCategoryItems = styled('div')`
  margin-left: 40px;
`;

const SkeletonText = ({ width }: { width: number }) => (
  <Skeleton sx={{ display: 'block' }} variant="text" width={width} />
);

export const LayoutSkeleton: React.FC = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Wrapper aria-label={t('appLoading')}>
      <NavigationBar variant="rectangular" />

      <Container>
        <Aside>
          <NavigationMenuContainer>
            <MenuCategory>
              <SkeletonText width={180} />

              <MenuCategoryItems>
                <SkeletonText width={120} />
                <SkeletonText width={150} />
              </MenuCategoryItems>
            </MenuCategory>

            <MenuCategory>
              <SkeletonText width={150} />

              <MenuCategoryItems>
                <SkeletonText width={80} />
                <SkeletonText width={100} />
              </MenuCategoryItems>
            </MenuCategory>

            <MenuCategory>
              <SkeletonText width={160} />

              <MenuCategoryItems>
                <SkeletonText width={150} />
                <SkeletonText width={120} />
              </MenuCategoryItems>
            </MenuCategory>
          </NavigationMenuContainer>
        </Aside>

        <Content>
          <Main>{children}</Main>
        </Content>
      </Container>
    </Wrapper>
  );
};
