import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const setupI18Next = () =>
  i18next
    .use(initReactI18next)
    .use(ChainedBackend)
    .init({
      backend: {
        backends: [
          new Backend(null, { loadPath: '/i18n/{{ns}}/{{lng}}.json' }),
          new Backend(null, { loadPath: '/lib-translations/{{lng}}/{{ns}}.json' }),
        ],
      },
      defaultNS: 'translations',
      fallbackLng: {
        default: ['en'],
      },
      interpolation: {
        escapeValue: false,
      },
      ns: ['translations'],
    });
