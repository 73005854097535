import { useState } from 'react';

import { Button } from '@goshippo/components';
import { Dialog } from '@goshippo/libraries/Dialog';

import Cookies from 'js-cookie';

import { SessionExpiredContext } from '~/src/contexts/SessionExpired';
import { useAuthentication } from '~/src/hooks/useAuthentication';
import { environment } from '~/src/utils/environment';

const COOKIE_OPTIONS = { domain: environment.SHIPPO_BASE_URL, path: '/' };

export const SessionExpiredProvider: React.FC = ({ children }) => {
  const [sessionExpired, setSessionExpired] = useState(false);

  const { loginWithRedirect } = useAuthentication();

  const redirectLogin = () => {
    // remove cookies
    Cookies.remove('jwt', COOKIE_OPTIONS);
    Cookies.remove('is_impersonate', COOKIE_OPTIONS);

    if (environment.LOCAL_AUTHENTICATION_ENABLED === 'true') {
      location.reload();
      return;
    }

    loginWithRedirect({ returnTo: `${location.pathname}${location.search}` });
  };

  return (
    <SessionExpiredContext.Provider value={{ sessionExpired, setSessionExpired }}>
      {children}

      <Dialog
        actions={[
          <Button color="secondary" key={0} onClick={redirectLogin} variant="text">
            Log in
          </Button>,
        ]}
        content="Your session has expired. Please log in again."
        onClose={redirectLogin}
        open={sessionExpired}
        title="Session expired"
      />
    </SessionExpiredContext.Provider>
  );
};
