import { Typography, colors, shippoTheme, useMediaQuery } from '@goshippo/components';

import { Navigate } from 'react-router-dom';

import { NavigationMenu } from './Layout/components/NavigationMenu';

export const Index = () => {
  const isMobile = useMediaQuery(shippoTheme.breakpoints.down('sm'), { noSsr: true });

  // Redirect to default desktop route
  if (!isMobile) return <Navigate to="/activity" />;

  return (
    <div>
      <Typography
        component="h2"
        sx={{ color: colors.text.primary.black, padding: 0.5 }}
        variant="h2"
      >
        Menu
      </Typography>
      <NavigationMenu />
    </div>
  );
};
