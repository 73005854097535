import { authTokenClient } from '~/src/clients/authToken/AuthTokenClient';
import { useAuthentication } from '~/src/hooks/useAuthentication';

type Props = {
  children: JSX.Element;
};

export const AuthTokenSetup = (props: Props): JSX.Element => {
  const { children } = props;
  const { getAccessTokenSilently, getTokenType } = useAuthentication();

  authTokenClient.setGetTokenTypeFn(getTokenType);
  authTokenClient.setGetTokenFn(getAccessTokenSilently);

  return children;
};
