import { useContext } from 'react';

import Cookies from 'js-cookie';

import { ShippoTokenContext } from '../contexts/ShippoToken';
import { environment } from '../utils/environment';

export const useAuthentication = () => {
  const shippoToken = useContext(ShippoTokenContext);
  const isImpersonatingCookie = Cookies.get('is_impersonate') || '';
  const isImpersonating = isImpersonatingCookie.toLowerCase() === 'true';

  return {
    getAccessTokenSilently: () => Promise.resolve(shippoToken),
    getTokenType: () => Promise.resolve('JWT' as const),
    isAuthenticated: !!shippoToken,
    isImpersonating,
    loginWithRedirect: ({ returnTo }: { returnTo: string }) => {
      if (environment.LOCAL_AUTHENTICATION_ENABLED === 'true') return;
      const encodedReturnURL = encodeURIComponent(`${location.origin}${returnTo}`);
      location.href = `${environment.WEB_APP_BASE_URL}/login?next=${encodedReturnURL}`;
    },
  };
};
