import { Box, Tooltip, Typography } from '@goshippo/components';

import { TFunction } from 'i18next';

import { rateCard1Color, rateCardEqualColor } from '../constants/rateCard';
import { ColumnValues } from '../services/SimulationService';
import { getRateTableTooltipContent } from '../utils/types';

type RateComparisonCellProps = {
  colorIndex: number;
  colors: string[];
  row: ColumnValues;
  t: TFunction;
};

export const RateComparisonCell = ({ colorIndex, colors, row, t }: RateComparisonCellProps) =>
  colors && (
    <Box textAlign={'center'}>
      <Tooltip
        disableInteractive
        variant="secondary"
        placement="top"
        arrow
        title={
          <Box p={'1rem'} textAlign={'center'}>
            {colors[colorIndex] !== rateCardEqualColor && (
              <Typography variant="h3" component="p" sx={{ color: 'white' }}>
                {colors[colorIndex] === rateCard1Color ? t('rateCard1') : t('rateCard2')}
                {t('is')}
              </Typography>
            )}
            <Typography variant="h3" component="p" sx={{ color: 'white' }}>
              {getRateTableTooltipContent(row, t)}
            </Typography>
          </Box>
        }
      >
        <Box sx={{ padding: '0 2rem', width: '100%' }}>
          <Typography component="p" variant="captionEmphasized">
            {t('price', {
              currency: 'USD',
              value: row.cheaper_rate,
            })}
          </Typography>
          <Typography component="p" variant="caption">
            {t('priceInParentheses', {
              currency: 'USD',
              value: Math.abs(row.price),
            })}
          </Typography>
          <div className="row-highlight-top" />
          <div className="row-highlight-bottom" />
        </Box>
      </Tooltip>
    </Box>
  );
