import { lazy } from 'react';

import { SettingsOutlined, colors } from '@goshippo/components';

import { Navigate, Route, Routes } from 'react-router-dom';

import { NavigationMenuCategory } from '~/src/app/components/Layout/components/NavigationMenu';
import { useUserEntitlements } from '~/src/hooks/useUserEntitlements';

const Labels = lazy(() => import('./pages/Labels'));

const CarrierAccounts = lazy(() => import('./pages/CarrierAccounts'));
const ParcelTemplates = lazy(() => import('./pages/ParcelTemplates'));
const Branding = lazy(() => import('./pages/Branding'));

export const FulfillmentNetworkRoutes = () => {
  const {
    entitlements: { hasCustomBranding },
  } = useUserEntitlements();

  return (
    <Routes>
      <Route element={<Labels />} path="labels" />
      <Route element={<CarrierAccounts />} path="carrier-accounts" />
      <Route element={<Navigate to="carrier-accounts" />} path="*" />
      <Route element={<ParcelTemplates />} path="parcel-templates" />
      {hasCustomBranding && <Route element={<Branding />} path="branding" />}
    </Routes>
  );
};

export const createSettingsNavigationCategory = (
  hasCustomBranding: boolean,
): NavigationMenuCategory => ({
  icon: <SettingsOutlined sx={{ fill: colors.secondary }} />,
  items: [
    {
      name: 'settings.items.carrierAccounts',
      route: '/settings/carrier-accounts',
    },
    {
      name: 'settings.items.labels',
      route: '/settings/labels',
    },
    {
      name: 'settings.items.parcelTemplates',
      route: '/settings/parcel-templates',
    },
    ...(hasCustomBranding
      ? [
          {
            name: 'settings.items.branding',
            route: '/settings/branding',
          },
        ]
      : []),
  ],
  name: 'settings.title',
});
