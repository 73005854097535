import { useTranslation } from 'react-i18next';

import { menuLinks } from '~/src/utils/menu';

import { Props, StyledMenu, StyledMenuItem, TruncatedText } from './NavigationBar';

export const DocumentationMenu: React.FC<Props> = ({ anchorEl, onCloseMenu, open }) => {
  const { t } = useTranslation('', { keyPrefix: 'navigationBar' });

  const navigateTo = (path: string) => {
    window.open(path, '_blank');
    onCloseMenu();
  };

  const apiGuides = () => navigateTo(menuLinks.apiGuides);
  const apiReference = () => navigateTo(menuLinks.apiReference);
  const clientLib = () => navigateTo(menuLinks.clientLib);
  const postmanCollection = () => navigateTo(menuLinks.postmanCollection);

  return (
    <StyledMenu
      MenuListProps={{
        'aria-labelledby': 'documentation-button',
      }}
      anchorEl={anchorEl}
      id="documentation-menu"
      onClose={onCloseMenu}
      open={open}
    >
      <StyledMenuItem component="a" onClick={apiGuides} sx={{ mt: '1rem' }}>
        <TruncatedText component="span">{t('apiGuides')}</TruncatedText>
      </StyledMenuItem>

      <StyledMenuItem component="a" onClick={apiReference}>
        <TruncatedText component="span">{t('apiReference')}</TruncatedText>
      </StyledMenuItem>

      <StyledMenuItem component="a" onClick={clientLib}>
        <TruncatedText component="span">{t('clientLib')}</TruncatedText>
      </StyledMenuItem>

      <StyledMenuItem component="a" onClick={postmanCollection}>
        <TruncatedText component="span">{t('postmanCollection')}</TruncatedText>
      </StyledMenuItem>
    </StyledMenu>
  );
};
