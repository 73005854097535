export type CreateShippoHttpErrorArgs = [string, number, Record<string, unknown> | undefined];

type FormError<FormFields extends object> = {
  [Key in keyof FormFields]?: string;
};

export class ShippoHttpClientError extends Error {
  errorMessage?: string;
  errorMessages?: string[];
  formValidationErrors?: Record<string, string[]>;

  constructor(
    public message: string,
    public status = 500,
    public response?: Record<string, unknown>,
  ) {
    super(typeof response?.error === 'string' ? response.error : message);

    this.name = 'ShippoHttpClientError';
    this.response = response;
    this.status = status;
  }

  public hasFormValidationErrors<T extends FormError<T>>(): this is {
    formValidationErrors: FormError<T>;
  } & this {
    return Boolean(this.formValidationErrors);
  }

  public hasMultipleErrorMessages(): this is { errorMessages: string[] } & this {
    return Boolean(this.errorMessages?.length);
  }

  public hasSingleErrorMessage(): this is { errorMessage: string } & this {
    return Boolean(this.errorMessage);
  }
}

export class ShippoBadRequestError extends ShippoHttpClientError {
  constructor(message: string, status = 400, response?: Record<string, unknown>) {
    super(message, status, response);
  }
}

export class ShippoAuthenticationError extends ShippoHttpClientError {
  constructor(message: string, status = 401, response?: Record<string, unknown>) {
    super(message, status, response);
  }
}

export class ShippoForbiddenError extends ShippoHttpClientError {
  constructor(message: string, status = 403, response?: Record<string, unknown>) {
    super(message, status, response);
  }
}

export class ShippoInternalError extends ShippoHttpClientError {
  constructor(message: string, status = 500, response?: Record<string, unknown>) {
    super(message, status, response);
  }
}
