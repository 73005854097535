import { omit } from 'lodash';
import { v4 as uuid } from 'uuid';

import {
  FeatureFlagAnonymousUserDataPayload,
  FeatureFlagUserData,
  FeatureFlagUserDataPayload,
} from '~/src/app/utils/featureflag/types';
import { useUserAccount } from '~/src/hooks/useUserAccount';
import { UserAccount } from '~/src/services/UserAccountService';
import { environment } from '~/src/utils/environment';

export const SEGMENT_ANONYMOUS_USER_ID_KEY = 'ajs_anonymous_id';

export const isShippoEmail = (email: string) =>
  email.endsWith('@shippo.com') || email.endsWith('@goshippo.com');

/**
 * Creates FeatrureFlagUserData from userAccount
 * @returns {FeatureFlagUserData} The user data object for feature flagging.
 */
const getFeatureFlagUserData = (data: UserAccount | undefined): FeatureFlagUserData | null => {
  const userAgent = navigator.userAgent;
  if (!data) return null;
  const {
    address,
    currentPromo,
    dateJoined,
    email,
    firstName,
    hasPurchasedLabel,
    id,
    isActive,
    lastName,
    lifetimeVolSegmentMax,
    lifetimeVolSegmentMin,
    revenueTierName,
  } = data;

  return {
    country: address && address.country ? address?.country : '',
    dateJoined: Date.parse(dateJoined),
    hasPromo: Boolean(currentPromo),
    hasPurchasedFirstLabel: Boolean(hasPurchasedLabel),
    hasPurchasedLabel,
    id,
    isActive,
    isE2EAutomatedTestUser:
      isShippoEmail(email) && (email.startsWith('test+') || email.startsWith('stester')),
    isE2EGetStartedUser: isShippoEmail(email) && email.startsWith('test-get-started+'),
    isE2ETestUser:
      (isShippoEmail(email) && email.startsWith('test+')) ||
      (firstName === 'Test' && lastName === 'Cafe'),
    isMobile: false,
    isShippoEmail: isShippoEmail(email),
    lifetimeVolSegmentMax,
    lifetimeVolSegmentMin,
    ordersCount: 0,
    primaryCarrier: '',
    proPlanVariant: '',
    registrationStore: '',
    revenueTierName,
    userAgent,
    userId: id,
    userType: '',
  };
};

const makeAnonymousUser = (): FeatureFlagAnonymousUserDataPayload => {
  let anonymousUserId = localStorage.getItem(SEGMENT_ANONYMOUS_USER_ID_KEY);
  if (!anonymousUserId) {
    anonymousUserId = uuid();
    // This comes with extra "" because of the way Segment stores the anonymous id
    localStorage.setItem(SEGMENT_ANONYMOUS_USER_ID_KEY, `"${anonymousUserId}"`);
  } else {
    // Clean up old anonymous user id
    // This comes with extra "" because of the way Segment stores the anonymous id
    anonymousUserId = anonymousUserId.replace(/"/g, '');
  }

  return {
    attributes: {
      anonymous: true,
      environmentName: environment.ENVIRONMENT_NAME,
    },
    id: anonymousUserId,
    type: 'anonymous',
  };
};

type UseUserDataForFeatureFlagHook = FeatureFlagUserDataPayload;

/**
 * This hook is designed to retrieve user data for feature flagging.
 * - It always returns anonymous user data with a locally stored UUID.
 * - If the user is logged in, it returns the user data object for feature flagging.
 * - If the user is not logged in, it returns the anonymous user data object for feature flagging.
 *
 * @returns {FeatureFlagUserDataPayload} The user data object for feature flagging.
 */
export const useUserDataForFeatureFlag = (): UseUserDataForFeatureFlagHook => {
  const { data } = useUserAccount();
  const featureFlagUserData = getFeatureFlagUserData(data);

  const anonymousUser = makeAnonymousUser();
  if (!featureFlagUserData) return anonymousUser;
  const { ...otherAttributes } = featureFlagUserData;

  const featureFlagOtherAttributes = omit(otherAttributes, ['id']);

  return {
    // We always use segment anonymous id for feature flags
    attributes: {
      ...featureFlagOtherAttributes,
      anonymous: false,
      environmentName: environment.ENVIRONMENT_NAME,
    },
    // to avoid extra MAUs and be consistent for reporting
    id: anonymousUser.id,
    type: 'logged',
  };
};
