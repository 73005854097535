import { z } from 'zod';

import { HttpClient } from '../clients/http/HttpClient';
import { djangoHttpClient } from '../clients/shippo/DjangoHttpClient';

const SWITCH_TYPE_SCHEMA = z.object({
  entitlement_type: z.literal('switch'),
  token: z.string(),
});

const QUANTITY_TYPE_SCHEMA = z.object({
  entitlement_type: z.literal('quantity'),
  is_unlimited: z.boolean(),
  quantity: z.number(),
  token: z.string(),
});

const CUSTOM_TYPE_SCHEMA = z.object({
  entitlement_type: z.literal('custom'),
  token: z.string(),
  value: z.string(),
});

const USER_ENTITLEMENTS_SCHEMA = z.array(
  z.union([SWITCH_TYPE_SCHEMA, QUANTITY_TYPE_SCHEMA, CUSTOM_TYPE_SCHEMA]),
);

type UserEntitlementsResponse = z.infer<typeof USER_ENTITLEMENTS_SCHEMA>;

type EntitlementToken = 'custom_branding' | 'shippo_staff' | 'support_type' | 'user_seats';

export type SwitchTypeEntitlement = {
  entitlementType: 'switch';
  token: EntitlementToken;
};

export type QuantityTypeEntitlement = {
  entitlementType: 'quantity';
  isUnlimited: boolean;
  quantity: number;
  token: EntitlementToken;
};

export type CustomTypeEntitlement = {
  entitlementType: 'custom';
  token: EntitlementToken;
  value: string;
};

export type UserEntitlements = (
  | CustomTypeEntitlement
  | QuantityTypeEntitlement
  | SwitchTypeEntitlement
)[];

export class UserEntitlementService {
  private parseEntitlements = (response: UserEntitlementsResponse): UserEntitlements =>
    response.map((entitlement) => {
      switch (entitlement.entitlement_type) {
        case 'switch':
          return {
            entitlementType: 'switch',
            token: entitlement.token as EntitlementToken,
          };
        case 'quantity':
          return {
            entitlementType: 'quantity',
            isUnlimited: entitlement.is_unlimited,
            quantity: entitlement.quantity,
            token: entitlement.token as EntitlementToken,
          };
        case 'custom':
          return {
            entitlementType: 'custom',
            token: entitlement.token as EntitlementToken,
            value: entitlement.value,
          };
      }
    });

  get = async (): Promise<UserEntitlements> => {
    const { body } = await this.httpClient.get<UserEntitlementsResponse>({
      url: '/entitlements',
    });

    const response = USER_ENTITLEMENTS_SCHEMA.parse(body);

    return this.parseEntitlements(response);
  };

  constructor(private readonly httpClient: HttpClient = djangoHttpClient) {}
}

export const userEntitlementService = new UserEntitlementService();
