import { lazy } from 'react';

import { Balance, CircularProgress, colors } from '@goshippo/components';

import { Navigate, Route, Routes } from 'react-router-dom';

import { NavigationMenuCategory } from '~/src/app/components/Layout/components/NavigationMenu';
import { useUserEntitlements } from '~/src/hooks/useUserEntitlements';

const ReconciliationSessions = lazy(() => import('./pages/Sessions'));
const ReconciliationSessionsDashboard = lazy(() => import('./pages/SessionsDashboard'));
const ReconciliationSession = lazy(() => import('./pages/Session'));
const ReconciliationSessionReview = lazy(() => import('./pages/SessionReview'));

export const ReconciliationRoutes = () => {
  const {
    entitlements: { isStaff },
    loading,
  } = useUserEntitlements();

  if (loading) return <CircularProgress />;

  if (!isStaff) return <Navigate to="/" />;

  return (
    <Routes>
      <Route element={<ReconciliationSessionsDashboard />} path="sessions/dashboard" />
      <Route element={<ReconciliationSessions />} path="sessions" />
      <Route element={<ReconciliationSession />} path="sessions/:sessionId" />
      <Route element={<ReconciliationSessionReview />} path="sessions/:sessionId/review" />
      <Route element={<Navigate to="sessions/dashboard" />} path="*" />
    </Routes>
  );
};

export const reconciliationNavigationCategory: NavigationMenuCategory = {
  icon: <Balance sx={{ fill: colors.secondary }} />,
  items: [
    {
      name: 'reconciliation.items.dashboard',
      route: '/reconciliation/sessions/dashboard',
    },
    {
      name: 'reconciliation.items.sessions',
      route: '/reconciliation/sessions',
    },
  ],
  name: 'reconciliation.title',
};
