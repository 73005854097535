import { z } from 'zod';

import { ENVIRONMENT_SCHEMA } from './schemas/environment';

export type EnvironmentVariables = z.infer<typeof ENVIRONMENT_SCHEMA>;

const getProxiedURL = (variable: string) => {
  const originalVariable = import.meta.env[`VITE_${variable}`];
  const proxyVariable = import.meta.env[`VITE_PROXY_${variable}`];
  const appBaseURL = import.meta.env.VITE_APP_BASE_URL;

  if (proxyVariable) return `${appBaseURL}${proxyVariable}`;
  return originalVariable;
};

export const getEnvironmentVariables = (): EnvironmentVariables => {
  try {
    return ENVIRONMENT_SCHEMA.parse({
      APP_BASE_URL: import.meta.env.VITE_APP_BASE_URL,
      ARMOR_API_BASE_URL: getProxiedURL('ARMOR_API_BASE_URL'),
      DJANGO_API_BASE_URL: getProxiedURL('DJANGO_API_BASE_URL'),
      DJANGO_BASE_URL: getProxiedURL('DJANGO_BASE_URL'),
      ENVIRONMENT_NAME: import.meta.env.VITE_ENVIRONMENT_NAME,
      FULL_STORY_DEBUG: import.meta.env.VITE_FULL_STORY_DEBUG,
      FULL_STORY_DEVMODE: import.meta.env.VITE_FULL_STORY_DEVMODE,
      FULL_STORY_HOST: import.meta.env.VITE_FULL_STORY_HOST,
      FULL_STORY_NAMESPACE: import.meta.env.VITE_FULL_STORY_NAMESPACE,
      FULL_STORY_ORGID: import.meta.env.VITE_FULL_STORY_ORGID,
      FULL_STORY_SCRIPT: import.meta.env.VITE_FULL_STORY_SCRIPT,
      LOCAL_AUTHENTICATION_ENABLED: import.meta.env.VITE_LOCAL_AUTHENTICATION_ENABLED,
      LOQATE_KEY: import.meta.env.VITE_LOQATE_KEY,
      MFE_TEMPLATE_URL: import.meta.env.VITE_MFE_TEMPLATE_URL,
      MUI_LICENSE_KEY: import.meta.env.VITE_MUI_LICENSE_KEY,
      NEW_RELIC_ACCOUNT_ID: import.meta.env.VITE_NEW_RELIC_ACCOUNT_ID,
      NEW_RELIC_AGENT_ID: import.meta.env.VITE_NEW_RELIC_AGENT_ID,
      NEW_RELIC_APPLICATION_ID: import.meta.env.VITE_NEW_RELIC_APPLICATION_ID,
      NEW_RELIC_ENABLED: import.meta.env.VITE_NEW_RELIC_ENABLED,
      NEW_RELIC_LICENSE_KEY: import.meta.env.VITE_NEW_RELIC_LICENSE_KEY,
      NEW_RELIC_TRUST_KEY: import.meta.env.VITE_NEW_RELIC_TRUST_KEY,
      OPTIMIZELY_SDK_KEY: import.meta.env.VITE_OPTIMIZELY_SDK_KEY,
      SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
      SENTRY_ENABLED: import.meta.env.VITE_SENTRY_ENABLED,
      SHIPPO_BASE_URL: import.meta.env.VITE_SHIPPO_BASE_URL,
      SHIPPO_PUBLIC_API_BASE_URL: getProxiedURL('SHIPPO_PUBLIC_API_BASE_URL'),
      SHIPPO_REPORTS_API_BASE_URL: getProxiedURL('SHIPPO_REPORTS_API_BASE_URL'),
      SUPPORT_URL: import.meta.env.VITE_SUPPORT_URL,
      TRANSCEND_CFA_SYNC: import.meta.env.VITE_TRANSCEND_CFA_SYNC,
      TRANSCEND_CSS: import.meta.env.VITE_TRANSCEND_CSS,
      TRANSCEND_ENABLED: import.meta.env.VITE_TRANSCEND_ENABLED,
      TRANSCEND_MESSAGES: import.meta.env.VITE_TRANSCEND_MESSAGES,
      TRANSCEND_SYNC_ENDPOINT: import.meta.env.VITE_TRANSCEND_SYNC_ENDPOINT,
      TRANSCEND_UI: import.meta.env.VITE_TRANSCEND_UI,
      TRANSCEND_URL: import.meta.env.VITE_TRANSCEND_URL,
      WEB_APP_BASE_URL: import.meta.env.VITE_WEB_APP_BASE_URL,
    });
  } catch (error) {
    if (error instanceof z.ZodError) {
      const errorMessages = error.issues
        .map((issue) => `Error validating env.${issue.path}: ${issue.message}`)
        .join('\n');
      throw new Error(errorMessages);
    }

    throw error;
  }
};

export const environment = getEnvironmentVariables();
