import { DataGridProProps } from '@goshippo/components';

import { useTranslation } from 'react-i18next';

import { DataTable } from '~/src/components/DataTable/DataTable';

import { ShipmentByLocation } from '../services/SimulationService';

type Props = {
  shipmentByLocation: ShipmentByLocation;
};

type Columns = DataGridProProps<ShipmentByLocation>['columns'];

export const ShipmentsByLocationTableReport = ({ shipmentByLocation }: Props) => {
  const { t } = useTranslation('simulate', { keyPrefix: 'simulation' });

  const columns: Columns = [
    {
      align: 'center',
      field: 'source_location',
      flex: 1,
      headerAlign: 'center',
      headerName: t('shipmentsByLocation.XAxisLabel'),
      renderCell: () => t('shipmentsByLocation.YAxisLabel'),
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zone1',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZone1'),
      renderCell: (params) => params.row.zones.zone1,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zone2',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZone2'),
      renderCell: (params) => params.row.zones.zone2,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zone3',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZone3'),
      renderCell: (params) => params.row.zones.zone3,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zone4',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZone4'),
      renderCell: (params) => params.row.zones.zone4,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zone5',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZone5'),
      renderCell: (params) => params.row.zones.zone5,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zone6',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZone6'),
      renderCell: (params) => params.row.zones.zone6,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zone7',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZone7'),
      renderCell: (params) => params.row.zones.zone7,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zone8',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZone8'),
      renderCell: (params) => params.row.zones.zone8,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zoneHI',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZoneHI'),
      renderCell: (params) => params.row.zones.zoneHI,
      sortable: false,
    },
    {
      align: 'center',
      field: 'zones.zoneAK',
      flex: 1,
      headerAlign: 'center',
      headerName: t('zones.tableZoneAK'),
      renderCell: (params) => params.row.zones.zoneAK,
      sortable: false,
    },
  ];

  return (
    <DataTable
      autoHeight
      columns={columns}
      getRowId={(row: ShipmentByLocation) => row.source_location}
      headerHeight={32}
      rows={[shipmentByLocation]}
      status="success"
      hideFooter
    />
  );
};
