import { TFunction } from 'i18next';
import { z } from 'zod';

import { rateCard1Color, rateCardEqualColor } from '../constants/rateCard';
import {
  ColumnValues,
  MarkupFormat,
  RateCardOrderHistoryComparisonBreakdown,
  RateCardOrderHistoryComparisonSummary,
  RateCardRow,
  WeightZoneComparisonRow,
  rateBreakdownKeys,
  zoneKeys,
} from '../services/SimulationService';

export enum RateCardSelected {
  RATE_CARD_1 = 'rateCard1',
  RATE_CARD_2 = 'rateCard2',
}

export enum ServiceRateCardNames {
  RATE_CARD_1 = 'Rate card 1',
  RATE_CARD_2 = 'Rate card 2',
}

export enum MarkUpSelected {
  MARK_UP_1 = 'markUp1',
  MARK_UP_2 = 'markUp2',
}

export enum SimulationState {
  ERROR = 'error',
  IDLE = 'idle',
  LOADING = 'loading',
  SUCCESS = 'success',
}

export enum SimulationTypes {
  RateCardComparison = 'rateCardComparison',
  ServiceLevelCost = 'serviceLevelCost',
  ServiceLevelPerformance = 'serviceLevelPerformance',
}

export const enum RateCardMeta {
  DIM = 'DIM',
  Rates = 'Rates',
  Surcharges = 'Surcharges',
}

export const enum DiscountType {
  delivery_area_surcharge = 'delivery_area_surcharge',
  fuel_surcharge_rate = 'fuel_surcharge_rate',
  residential_fee = 'residential_fee',
}

export interface UploadRateDataInterface {
  delivery_area_surcharge: number;
  dim_factor: number;
  fuel_surcharge_rate: number;
  rate_card_name: string;
  residential_fee: number;
  service_level: string;
  user_edited_rate_card?: boolean;
}

export const normalizedRateCardFileHeaders = [
  { key: 'weight', label: 'Weight' },
  { key: 'weight_unit', label: 'Weight unit' },
  { key: 'zone1', label: 'Zone 1' },
  { key: 'zone2', label: 'Zone 2' },
  { key: 'zone3', label: 'Zone 3' },
  { key: 'zone4', label: 'Zone 4' },
  { key: 'zone5', label: 'Zone 5' },
  { key: 'zone6', label: 'Zone 6' },
  { key: 'zone7', label: 'Zone 7' },
  { key: 'zone8', label: 'Zone 8' },
  { key: 'zoneAK', label: 'Zone AK' },
  { key: 'zoneHI', label: 'Zone HI' },
];

export type UploadRateData = z.infer<typeof uploadRateSchema>;
// might need to change these to optional after sl
export const uploadRateSchema = z.object({
  delivery_area_surcharge: z
    .union([z.number().min(0).max(100), z.literal('').transform(() => null)])
    .nullable(),
  dim_factor: z.number().positive(),
  fuel_surcharge_rate: z.number().min(0).max(100),
  rate_card_name: z.string().min(1, 'Rate card name is required'),
  residential_fee: z.number().min(0).max(100),
  service_level: z.string(),
  user_edited_rate_card: z.boolean().optional().default(false),
});

export const breakDownGraphColors = ['#01474F', ' #DDC5FF', '#25B195', '#FFC624'];

export const totalCostGraphColors = ['#FFC624', '#25B195'];

export const shipmentsByLocationGraphColor = '#FFC624';

export type RateBreakdown = {
  base: number;
  delivery_area_surcharge: number;
  fuel_surcharge_rate: number;
  name: string;
  residential_fee: number;
};

export const transformBreakdownData = (
  data: RateCardOrderHistoryComparisonBreakdown,
): RateBreakdown[] =>
  Object.entries(data).map(([name, values]) => ({
    name: name.replace(/_/g, ' ').replace(/^\w/, (c) => c.toUpperCase()),
    ...values,
  }));

export const getBreakdownPercentData = (data: RateBreakdown[]): RateBreakdown[] => {
  const percentData: RateBreakdown[] = [];
  data.map((rateBreakdown: RateBreakdown) => {
    const totalCost = getTotalCostFromBreakdown(rateBreakdown);
    const percentBreakdown = { ...rateBreakdown };
    for (const key of rateBreakdownKeys) {
      percentBreakdown[key] = parseFloat(((percentBreakdown[key] / totalCost) * 100).toFixed(2));
    }
    percentData.push(percentBreakdown);
  });
  return percentData;
};

export type RateCardSummary = {
  name: string;
  total_cost: number;
};

export const transformSummaryData = (
  data: RateCardOrderHistoryComparisonSummary,
): RateCardSummary[] =>
  Object.entries(data)
    .filter(([key]) => key.endsWith('_total_cost'))
    .map(([key, value]) => ({
      name: `Rate Card ${key.split('_')[2]}`,
      total_cost: value,
    }));

export const formatLegendText = (value: string) =>
  value
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const validatePercentage = (value: number) => {
  if (value === null || value === undefined) {
    return 'Percentage is required';
  }

  if (isNaN(value) || value < 0 || value > 100) {
    return 'Percentage must be between 0 and 100';
  }

  return '';
};

export const validateMarkup = (value: number, markupFormat: MarkupFormat) => {
  if (isNaN(value)) {
    return 'Please enter a valid number';
  }
  if (markupFormat === MarkupFormat.PERCENTAGE) {
    return validatePercentage(value);
  } else {
    if (!/^\$?(\d{1,3}(,\d{3})*|\d+)(\.\d{1,2})?$/.test(value.toString())) {
      return 'Please enter a valid price';
    }
  }

  return '';
};

export const getTooltipContent = (item: WeightZoneComparisonRow, index: number, t: TFunction) => {
  const zones = zoneKeys.filter((key) => key.startsWith('zone'));

  if (index >= 0 && index < zones.length) {
    const zoneKey = zones[index] as keyof Omit<RateCardRow, 'weight'>;
    const zone = item[zoneKey];

    if (typeof zone === 'object' && zone !== null && 'percentage' in zone && 'price' in zone) {
      const percentage = Math.abs(zone.percentage);
      const price = Math.abs(zone.price);

      if (price === 0) {
        return t('identicalRates');
      }

      const formattedPrice = t('price', {
        currency: 'USD',
        value: price,
      });

      return t('priceComparisonTooltip', {
        percentage: percentage.toFixed(2),
        price: formattedPrice,
      });
    }
  }

  return null;
};

export const getRateTableTooltipContent = (item: ColumnValues, t: TFunction) => {
  const percentage = Math.abs(item.percentage);
  const price = Math.abs(item.price);

  if (price === 0) {
    return t('identicalRates');
  }

  const formattedPrice = t('price', {
    currency: 'USD',
    value: price,
  });

  return t('priceComparisonTooltip', {
    percentage: percentage.toFixed(2),
    price: formattedPrice,
  });
};

export const countGreenZones = (data: WeightZoneComparisonRow[]) => {
  let totalGreenCount = 0;
  let totalZones = 0;

  data.forEach((item) => {
    // remove the last color in the array which is for zone 9 and equal color
    const relevantZonesColors = item.colors
      .slice(0, -1)
      .filter((color) => color !== rateCardEqualColor);
    const rateCard1Count = relevantZonesColors.filter((color) => color === rateCard1Color).length;
    totalGreenCount += rateCard1Count;
    totalZones += relevantZonesColors.length;
  });

  return {
    rateCard1Count: totalGreenCount,
    rateCard2Count: totalZones - totalGreenCount,
    totalZones,
  };
};

export const transformMapToRateCardRows = (dataMap: Map<unknown, RateCardRow>): RateCardRow[] =>
  Array.from(dataMap.values()).map((row) => ({
    id: Number(row.id),
    weight: Number(row.weight),
    weight_unit: String(row.weight_unit),
    zone1: Number(row.zone1),
    zone2: Number(row.zone2),
    zone3: Number(row.zone3),
    zone4: Number(row.zone4),
    zone5: Number(row.zone5),
    zone6: Number(row.zone6),
    zone7: Number(row.zone7),
    zone8: Number(row.zone8),
    zoneAK: Number(row.zoneAK),
    zoneHI: Number(row.zoneHI),
  }));

export const validateRate = (rate: number) => {
  const value = Number(rate);
  if (value === null || value === undefined || value === 0) {
    return 'value is required';
  }
  // only 2 decimals
  if (!/^\$?(?:(?:\d{1,3}(?:,\d{3})*|\d+)?(?:\.\d{1,2})?|\.\d{1,2})$/.test(value.toString())) {
    return 'Please enter a valid price';
  }

  return '';
};

export const getTotalCostFromBreakdown = (breakdown: RateBreakdown): number =>
  rateBreakdownKeys.reduce((total, key) => total + breakdown[key], 0);

export const getWeightHeader = (weight: number) => {
  if (weight > 0.99 && weight < 1.0) {
    return weight.toString().slice(0, 4);
  }
  return weight;
};
