import { BrowserAgent } from '@newrelic/browser-agent';

type CustomAttributes = {
  id: string;
} & Record<string, unknown>;

class NewRelicClient {
  private instance: BrowserAgent | null = null;

  addPageAction(name: string, attributes?: Record<string, unknown>) {
    this.instance?.addPageAction(name, attributes);
  }

  noticeError(error: Error, customAttributes: CustomAttributes) {
    if (!this.instance) return;

    this.instance.noticeError(error, customAttributes);
  }

  setInstance(instance: BrowserAgent) {
    this.instance = instance;
  }

  setUserId(userId: string) {
    this.instance?.setUserId(userId);
  }
}

export const newRelic = new NewRelicClient();
