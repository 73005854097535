import { useEffect } from 'react';

import { DataGridPro, DataGridProProps, colors } from '@goshippo/components';

import { captureException } from '@sentry/react';

import { HttpClientError } from '../../clients/http/HttpClient';
import { TechnicalErrorAlert } from '../TechnicalErrorAlert';
import { DataTableLoadingSkeleton } from './DataTableLoadingSkeleton';

export type DataTableProps<T extends object> = {
  error?: HttpClientError | null;
  loadingLabel?: string;
  loadingRows?: number;
  refetch?: () => void;
  status: 'error' | 'idle' | 'loading' | 'success';
} & Omit<DataGridProProps<T>, 'error'>;

export const DataTable = <T extends object>({
  error,
  loadingLabel,
  loadingRows = 10,
  refetch,
  rows,
  status,
  ...dataGridProProps
}: DataTableProps<T>) => {
  useEffect(() => {
    if (error) captureException(error);
  }, [error]);

  if (error) return <TechnicalErrorAlert error={error} refetch={refetch} />;

  if (status === 'loading') {
    return (
      <DataTableLoadingSkeleton
        loadingLabel={loadingLabel}
        loadingRows={loadingRows}
        rows={rows}
        {...dataGridProProps}
      />
    );
  }

  return (
    <DataGridPro
      rowHeight={rows.length === 0 ? 150 : 64}
      rows={rows}
      disableColumnMenu
      disableColumnReorder
      disableColumnSelector
      disableSelectionOnClick
      {...dataGridProProps}
      sx={{
        ' .MuiDataGrid-columnHeaders': {
          backgroundColor: colors.gray20,
          borderBottom: `1px solid ${colors.gray30}`,
          borderRadius: 0,
        },
        '& .MuiDataGrid-cell': {
          color: colors.text.default,
          fontSize: '1rem',
        },
        '& .MuiDataGrid-cell, & .MuiDataGrid-cell:focus': {
          backgroundColor: 'unset',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          color: colors.text.secondary,
          fontWeight: 700,
        },

        borderLeft: 'none',
        borderRadius: 0,
        borderRight: 'none',
        borderTop: `1px solid ${colors.gray30}`,
      }}
    />
  );
};
