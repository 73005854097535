import { useContext } from 'react';

import { MicroFrontendContextProvider } from '@shippo/mfe-framework';

import { OptimizelyContext } from '@optimizely/react-sdk';
import { captureException } from '@sentry/browser';

import { useAuthentication } from '~/src/hooks/useAuthentication';
import { useSessionExpired } from '~/src/hooks/useSessionExpired';
import { useSnackbar } from '~/src/hooks/useSnackbar';

type Props = {
  children: React.ReactNode;
};

export const MicroFrontendProvider = ({ children }: Props) => {
  const { optimizely } = useContext(OptimizelyContext);

  const { getAccessTokenSilently, getTokenType } = useAuthentication();
  const { setSessionExpired } = useSessionExpired();
  const snackbar = useSnackbar();

  return (
    <MicroFrontendContextProvider
      value={{
        // Segment is not set up for Portal
        analytics: {
          track: () => undefined,
        },
        authentication: {
          getToken: getAccessTokenSilently,
          getTokenType,
          handleSessionExpired: () => setSessionExpired(true),
        },
        experimentation: {
          getFeatureVariation: (feature: string) => {
            if (!optimizely) return 'off';
            const decision = optimizely.decide(feature);
            return decision.variationKey || 'off';
          },
          isFeatureEnabled: (feature: string) => {
            if (!optimizely) return false;
            const decision = optimizely.decide(feature);
            return decision.variationKey === 'on';
          },
        },
        monitoring: {
          captureException: (options) => {
            captureException(options.error);
          },
        },
        snackbar: {
          show: snackbar.show,
        },
      }}
    >
      {children}
    </MicroFrontendContextProvider>
  );
};
