export const formatShortAmount = (amount: number, options?: Intl.NumberFormatOptions): string => {
  const TRILLION = 1_000_000_000_000;
  const BILLION = 1_000_000_000;
  const MILLION = 1_000_000;
  const THOUSAND = 1_000;

  const absoluteAmount = Math.abs(amount);

  let suffix = '';
  let number = amount;

  if (absoluteAmount >= TRILLION) {
    suffix = ' T';
    number = amount / TRILLION;
  } else if (absoluteAmount >= BILLION) {
    suffix = ' B';
    number = amount / BILLION;
  } else if (absoluteAmount >= MILLION) {
    suffix = ' M';
    number = amount / MILLION;
  } else if (absoluteAmount >= THOUSAND) {
    suffix = ' K';
    number = amount / THOUSAND;
  }

  return `${formatAmount(number, { minimumFractionDigits: 0, ...options })}${suffix}`;
};

export const formatAmount = (amount: number, options?: Intl.NumberFormatOptions): string =>
  new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
    ...options,
  }).format(amount);
